// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom'; // Import useLocation
// import { submitCandidateForm } from '../../services/api';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const CandidateForm = () => {
//   const location = useLocation(); // Get the current location
//   const [merchantId, setMerchantId] = useState('');

//   useEffect(() => {
//     // Extract the merchant ID from the URL using a regular expression
//     const regex = /\/([^/?]+)$/;
//     const match = location.search.match(regex);
//     if (match) {
//       const merchantIdFromUrl = match[1];
//       console.log('Merchant ID from URL:', merchantIdFromUrl);
//       setMerchantId(merchantIdFromUrl);
//     }
//   }, [location.search]);

//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     merchantId: '',
//   });
//   const [file, setFile] = useState(null);

//   useEffect(() => {
//     setFormData((prevData) => ({ ...prevData, merchantId }));
//   }, [merchantId]);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     data.append('name', formData.name);
//     data.append('email', formData.email);
//     data.append('phone', formData.phone);
//     data.append('document', file);
//     data.append('merchantId', formData.merchantId);

//     try {
//       await submitCandidateForm(data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       alert('Form submitted successfully');
//     } catch (error) {
//       console.error('Error submitting form', error);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card">
//             <div className="card-body">
//               <h2 className="card-title text-center">Submit Candidate Form</h2>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="name" className="form-label">Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     name="name"
//                     placeholder="Name"
//                     value={formData.name}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">Email</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="phone" className="form-label">Phone</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="phone"
//                     name="phone"
//                     placeholder="Phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 {/* <div className="mb-3">
//                   <label htmlFor="merchantId" className="form-label">Merchant ID</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="merchantId"
//                     name="merchantId"
//                     placeholder="Merchant ID"
//                     value={formData.merchantId}
//                     readOnly
//                   />
//                 </div> */}
//                 <div className="mb-3">
//                   <label htmlFor="document" className="form-label">Document</label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="document"
//                     name="document"  
//                     onChange={handleFileChange}
//                   />
//                 </div>
//                 <div className="d-grid">
//                   <button type="submit" className="btn btn-primary">Submit</button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CandidateForm;



// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { submitCandidateForm } from '../../services/api';

// const CandidateForm = () => {
//   const location = useLocation();
//   const [merchantId, setMerchantId] = useState('');
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     merchantId: '',
//   });
//   const [file, setFile] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     const regex = /\/([^/?]+)$/;
//     const match = location.search.match(regex);
//     if (match) {
//       const merchantIdFromUrl = match[1];
//       console.log('Merchant ID from URL:', merchantIdFromUrl);
//       setMerchantId(merchantIdFromUrl);
//     }
//   }, [location.search]);

//   useEffect(() => {
//     setFormData((prevData) => ({ ...prevData, merchantId }));
//   }, [merchantId]);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const validate = () => {
//     let tempErrors = {};
//     tempErrors.name = formData.name ? '' : 'This field is required.';
//     tempErrors.email = /\S+@\S+\.\S+/.test(formData.email) ? '' : 'Email is not valid.';
//     tempErrors.phone = formData.phone.length > 9 ? '' : 'Minimum 10 numbers required.';
//     tempErrors.file = file ? '' : 'This field is required.';
//     setErrors({ ...tempErrors });
//     return Object.values(tempErrors).every(x => x === '');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       const data = new FormData();
//       data.append('name', formData.name);
//       data.append('email', formData.email);
//       data.append('phone', formData.phone);
//       data.append('merchantId', formData.merchantId);
//       data.append('document', file);

//       try {
//         const response = await submitCandidateForm(data, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });

//         if (response && response.data && response.data.success) {
//           Swal.fire({
//             icon: 'success',
//             title: 'Success',
//             text: response.data.message || 'Form submitted successfully',
//           });
//         } else {
//           Swal.fire({
//             icon: 'success',
//             title: 'success',
//             text: 'Form submitted successfully' ,
//           });
//         }
//       } catch (error) {
//         if (error.response && error.response.data && error.response.data.message) {
//           Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: error.response.data.message,
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: 'Error submitting form',
//           });
//         }
//         console.error('Error submitting form', error);
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       Swal.fire({
//         icon: 'error',
//         title: '',
//         text: 'Please fill all the fields in the form and try again.',
//       });
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card">
//             <div className="card-body">
//               <h2 className="card-title text-center">Submit Candidate Form</h2>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="name" className="form-label">Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     name="name"
//                     placeholder="Name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.name && <div className="text-danger">{errors.name}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">Email</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.email && <div className="text-danger">{errors.email}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="phone" className="form-label">Phone</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="phone"
//                     name="phone"
//                     placeholder="Phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.phone && <div className="text-danger">{errors.phone}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="document" className="form-label">Document</label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="document"
//                     name="document"
//                     onChange={handleFileChange}
//                     disabled={loading}
//                   />
//                   {errors.file && <div className="text-danger">{errors.file}</div>}
//                 </div>
//                 <div className="d-grid">
//                   <button type="submit" className="btn btn-primary" disabled={loading}>
//                     {loading ? (
//                       <span>
//                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                         submitting...
//                       </span>
//                     ) : (
//                       'Submit'
//                     )}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CandidateForm;




// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { submitCandidateForm } from '../../services/api';

// const CandidateForm = () => {
//   const location = useLocation();
//   const [merchantId, setMerchantId] = useState('');
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     merchantId: '',
//   });
//   const [file, setFile] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     const regex = /\/([^/?]+)$/;
//     const match = location.search.match(regex);
//     if (match) {
//       const merchantIdFromUrl = match[1];
//       // console.log('Merchant ID from URL:', merchantIdFromUrl);
//       setMerchantId(merchantIdFromUrl);
//     }
//   }, [location.search]);

//   useEffect(() => {
//     setFormData((prevData) => ({ ...prevData, merchantId }));
//   }, [merchantId]);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const validate = () => {
//     let tempErrors = {};
//     const nameRegex = /^[a-zA-Z0-9.]+$/;
//     const phoneRegex = /^\d{10}$/;
//     const emailRegex = /^\S+@\S+\.\S+$/;

//     tempErrors.name = nameRegex.test(formData.name) ? '' : 'Name can only contain letters, numbers, and periods.';
//     tempErrors.email = emailRegex.test(formData.email) ? '' : 'Email is not valid.';
//     tempErrors.phone = phoneRegex.test(formData.phone) ? '' : 'Phone number must be exactly 10 digits.';
//     tempErrors.file = file ? '' : 'This field is required.';
//     setErrors({ ...tempErrors });
//     return Object.values(tempErrors).every(x => x === '');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       const data = new FormData();
//       data.append('name', formData.name);
//       data.append('email', formData.email);
//       data.append('phone', formData.phone);
//       data.append('merchantId', formData.merchantId);
//       data.append('document', file);

//       try {
//         const response = await submitCandidateForm(data, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });

//         if (response && response.data && response.data.success) {
//           Swal.fire({
//             icon: 'success',
//             title: 'Success',
//             text: response.data.message || 'Form submitted successfully',
//           });
//         } else {
//           Swal.fire({
//             icon: 'success',
//             title: 'Success',
//             text: 'Form submitted successfully',
//           });
//         }
//       } catch (error) {
//         if (error.response && error.response.data && error.response.data.message) {
//           Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: error.response.data.message,
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: 'Error submitting form',
//           });
//         }
//         console.error('Error submitting form', error);
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       Swal.fire({
//         icon: 'error',
//         title: '',
//         text: 'Please fill all the fields in the form correctly and try again.',
//       });
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card">
//             <div className="card-body">
//               <h2 className="card-title text-center">Submit Candidate Form</h2>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="name" className="form-label">Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     name="name"
//                     placeholder="Name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.name && <div className="text-danger">{errors.name}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">Email</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.email && <div className="text-danger">{errors.email}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="phone" className="form-label">Phone</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="phone"
//                     name="phone"
//                     placeholder="Phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.phone && <div className="text-danger">{errors.phone}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="document" className="form-label">Document</label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="document"
//                     name="document"
//                     onChange={handleFileChange}
//                     disabled={loading}
//                   />
//                   {errors.file && <div className="text-danger">{errors.file}</div>}
//                 </div>
//                 <div className="d-grid">
//                   <button type="submit" className="btn btn-primary" disabled={loading}>
//                     {loading ? (
//                       <span>
//                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                         submitting...
//                       </span>
//                     ) : (
//                       'Submit'
//                     )}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CandidateForm;



// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { submitCandidateForm } from '../../services/api';

// const CandidateForm = () => {
//   const location = useLocation();
//   const [merchantId, setMerchantId] = useState('');
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     merchantId: '',
//   });
//   const [file, setFile] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     const regex = /\/([^/?]+)$/;
//     const match = location.search.match(regex);
//     if (match) {
//       const merchantIdFromUrl = match[1];
//       setMerchantId(merchantIdFromUrl);
//     }
//   }, [location.search]);

//   useEffect(() => {
//     setFormData((prevData) => ({ ...prevData, merchantId }));
//   }, [merchantId]);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const validate = () => {
//     let tempErrors = {};
//     const nameRegex = /^[a-zA-Z0-9.]+$/;
//     const phoneRegex = /^\d{10}$/;
//     const emailRegex = /^\S+@\S+\.\S+$/;

//     tempErrors.name = nameRegex.test(formData.name) ? '' : 'Name can only contain letters, numbers, and periods.';
//     tempErrors.email = emailRegex.test(formData.email) ? '' : 'Email is not valid.';
//     tempErrors.phone = phoneRegex.test(formData.phone) ? '' : 'Phone number must be exactly 10 digits.';
//     tempErrors.file = file ? '' : 'This field is required.';
//     setErrors({ ...tempErrors });
//     return Object.values(tempErrors).every(x => x === '');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       const data = new FormData();
//       data.append('name', formData.name);
//       data.append('email', formData.email);
//       data.append('phone', formData.phone);
//       data.append('merchantId', formData.merchantId);
//       data.append('document', file);

//       try {
//         const response = await submitCandidateForm(data, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });

//         if (response && response.data && response.data.success) {
//           Swal.fire({
//             icon: 'success',
//             title: 'Success',
//             text: response.data.message || 'Form submitted successfully',
//           });
//         } else {
//           Swal.fire({
//             icon: 'success',
//             title: 'Success',
//             text: 'Form submitted successfully',
//           });
//         }
//       } catch (error) {
//         if (error.response && error.response.data && error.response.data.message) {
//           Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: error.response.data.message,
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: 'Error',
//             text: 'Error submitting form',
//           });
//         }
//         console.error('Error submitting form', error);
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       Swal.fire({
//         icon: 'error',
//         title: '',
//         text: 'Please fill all the fields in the form correctly and try again.',
//       });
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card">
//             <div className="card-body">
//               <h2 className="card-title text-center">Submit Candidate Form</h2>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="name" className="form-label">Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     name="name"
//                     placeholder="Name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.name && <div className="text-danger">{errors.name}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">Email</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.email && <div className="text-danger">{errors.email}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="phone" className="form-label">Phone</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="phone"
//                     name="phone"
//                     placeholder="Phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                     disabled={loading}
//                   />
//                   {errors.phone && <div className="text-danger">{errors.phone}</div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="document" className="form-label">Document</label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="document"
//                     name="document"
//                     onChange={handleFileChange}
//                     disabled={loading}
//                   />
//                   {errors.file && <div className="text-danger">{errors.file}</div>}
//                 </div>
//                 <div className="d-grid">
//                   <button type="submit" className="btn btn-primary" disabled={loading}>
//                     {loading ? (
//                       <span>
//                         <span className="spinner-border spinner-border-sm ms-3" role="status" aria-hidden="true"></span>
//                         submitting...
//                       </span>
//                     ) : (
//                       'Submit'
//                     )}
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CandidateForm;



import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import { submitCandidateForm } from '../../services/api';

const CandidateForm = () => {
  const location = useLocation();
  const [merchantId, setMerchantId] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    merchantId: '',
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const regex = /\/([^/?]+)$/;
    const match = location.search.match(regex);
    if (match) {
      const merchantIdFromUrl = match[1];
      setMerchantId(merchantIdFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, merchantId }));
  }, [merchantId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validate = () => {
    let tempErrors = {};
    const nameRegex = /^[a-zA-Z\s]+$/;
    const phoneRegex = /^\d{10,15}$/;
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([0-9a-z][0-9a-z-]*[0-9a-z]\.)+[a-z]{2,4}$/i;

    tempErrors.name = nameRegex.test(formData.name) ? '' : 'Name can only contain letters and spaces.';
    tempErrors.email = emailRegex.test(formData.email) ? '' : 'Email is not valid.';
    tempErrors.phone = phoneRegex.test(formData.phone) ? '' : 'Phone number must be in numerical.';
    tempErrors.file = file ? '' : 'This field is required.';
    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every(x => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const data = new FormData();
      data.append('name', formData.name);
      data.append('email', formData.email);
      data.append('phone', formData.phone);
      data.append('merchantId', formData.merchantId);
      data.append('document', file);

      try {
        const response = await submitCandidateForm(data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response && response.data && response.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message || 'Form submitted successfully',
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Form submitted successfully',
          });
        }
        // Reset form after submission
        setFormData({
          name: '',
          email: '',
          phone: '',
          merchantId: merchantId,
        });
        setFile(null);
        setErrors({});
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error submitting form',
          });
        }
        console.error('Error submitting form', error);
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Please fill all the fields in the form correctly and try again.',
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Submit Candidate Form</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  {errors.phone && <div className="text-danger">{errors.phone}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="document" className="form-label">Document</label>
                  <input
                    type="file"
                    className="form-control"
                    id="document"
                    name="document"
                    onChange={handleFileChange}
                    disabled={loading}
                  />
                  {errors.file && <div className="text-danger">{errors.file}</div>}
                </div>
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? (
                      <span>
                        <span className="spinner-border spinner-border-sm ms-3" role="status" aria-hidden="true"></span>
                       <p className='ms-4'>submitting...</p> 
                      </span>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateForm;

// import React, { useState } from 'react';
// import { createUser } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';

// const CreateUserForm = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: '',
//     access_level: '',
//     department: ''
//   });

//   const { user } = useAuth();
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!user || !user.merchant_id) {
//       alert('Merchant ID not found');
//       return;
//     }

//     try {
//       const response = await createUser(formData);
//       if (response.success) {
//         alert('User created successfully');
//         navigate('/dashboard'); 
//       } else {
//         alert('Error creating user');
//       }
//     } catch (error) {
//       console.error('Error creating user', error);
//       alert('Error creating user');
//     }
//   };

//   return (
//     <div>
//       <h2>Create User</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           name="username"
//           placeholder="Username"
//           value={formData.username}
//           onChange={handleChange}
//           required
//         />
//         <input
//           type="email"
//           name="email"
//           placeholder="Email"
//           value={formData.email}
//           onChange={handleChange}
//           required
//         />
//         <input
//           type="text"
//           name="phone"
//           placeholder="Phone"
//           value={formData.phone}
//           onChange={handleChange}
//           required
//         />
//         <input
//           type="password"
//           name="password"
//           placeholder="Password"
//           value={formData.password}
//           onChange={handleChange}
//           required
//         />
     
//         <input
//         type="text"
//         name="access_level"
//         placeholder="access_level"
//         value={formData.access_level}
//         onChange={handleChange}
//         required
//       />
        
        
   
//         <input
//           type="text"
//           name="department"
//           placeholder="Department"
//           value={formData.department}
//           onChange={handleChange}
//           required
//         />
//         <button type="submit">Create User</button>
//       </form>
//     </div>
//   );
// };

// export default CreateUserForm;


// import React, { useState } from 'react';
// import { createUser } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';

// const CreateUserForm = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: '',
//     access_level: '',
//     department: ''
//   });

//   const { user } = useAuth();
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!user || !user.merchant_id) {
//       alert('Merchant ID not found');
//       return;
//     }

//     try {
//       const response = await createUser(formData);
//       if (response.success) {
//         alert('User created successfully');
//         navigate('/dashboard'); 
//       } else {
//         alert('Error creating user');
//       }
//     } catch (error) {
//       console.error('Error creating user', error);
//       alert('Error creating user');
//     }
//   };

//   return (
//     <div className="row mt-5">
//       <div className='col-lg-5'>

    
//       <h2>Create User</h2>
//       <form onSubmit={handleSubmit}>
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             name="username"
//             placeholder="Username"
//             value={formData.username}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="email"
//             className="form-control"
//             name="email"
//             placeholder="Email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             name="phone"
//             placeholder="Phone"
//             value={formData.phone}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="password"
//             className="form-control"
//             name="password"
//             placeholder="Password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             name="access_level"
//             placeholder="Access Level"
//             value={formData.access_level}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             name="department"
//             placeholder="Department"
//             value={formData.department}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <button type="submit" className="btn btn-primary">Create User</button>
//       </form>
//       </div>
//     </div>
//   );
// };

// export default CreateUserForm;


// import React, { useState } from 'react';
// import { createUser } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';

// const CreateUserForm = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: '',
//     access_level: '',
//     department: ''
//   });

//   const { user } = useAuth();
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!user || !user.merchant_id) {
//       alert('Merchant ID not found');
//       return;
//     }

//     try {
//       const response = await createUser(formData);
//       if (response.success) {
//         alert('User created successfully');
//         navigate('/dashboard'); 
//       } else {
//         // Handle error response
//         if (response.message === 'Username already exists') {
//           alert('Username already exists');
//         } else if (response.message === 'Email already exists') {
//           alert('Email already exists');
//         } else {
//           alert('Error creating user');
//         }
//       }
//     } catch (error) {
//       console.error('Error creating user', error);
//       alert('Error creating user');
//     }
//   };

//   return (
//     <div className="row mt-5">
//       <div className='col-lg-5'>
//         <h2>Create User</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="username"
//               placeholder="Username"
//               value={formData.username}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="email"
//               className="form-control"
//               name="email"
//               placeholder="Email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="phone"
//               placeholder="Phone"
//               value={formData.phone}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="password"
//               className="form-control"
//               name="password"
//               placeholder="Password"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="access_level"
//               placeholder="Access Level"
//               value={formData.access_level}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="department"
//               placeholder="Department"
//               value={formData.department}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <button type="submit" className="btn btn-primary">Create User</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CreateUserForm;



// import React, { useState } from 'react';
// import { createUser } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// const CreateUserForm = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: '',
//     department: ''
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [loading, setLoading] = useState(false);

//   const { user } = useAuth();
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!user || !user.merchant_id) {
//       setErrorMessage('Merchant ID not found');
//       return;
//     }

//     // Set access level to 3
//     const newUser = { ...formData, access_level: '3' };
//     setLoading(true);

//     try {
//       const response = await createUser(newUser);
//       if (response.success) {
//         Swal.fire({
//           icon: 'success',
//           title: 'User Created',
//           text: 'User created successfully',
//         }).then(() => {
//           navigate('/dashboard');
//         });
//       } else {
//         setErrorMessage(response.error || 'Error creating user');
//       }
//     } catch (error) {
//       console.error('Error creating user', error);
//       setErrorMessage('Error creating user');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="row mt-5">
//       <div className='col-lg-5'>
//         <h2>Create User</h2>
//         <form onSubmit={handleSubmit}>
//           {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="username"
//               placeholder="Username"
//               value={formData.username}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="email"
//               className="form-control"
//               name="email"
//               placeholder="Email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="phone"
//               placeholder="Phone"
//               value={formData.phone}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className="mb-3 position-relative">
//             <input
//               type={showPassword ? "text" : "password"}
//               className="form-control"
//               name="password"
//               placeholder="Password"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//             <button
//               type="button"
//               className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
//               onClick={togglePasswordVisibility}
//             >
//               <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//             </button>
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               className="form-control"
//               name="department"
//               placeholder="Department"
//               value={formData.department}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <button type="submit" className="btn btn-primary" disabled={loading}>
//             {loading ? (
//               <>
//                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                 Creating...
//               </>
//             ) : (
//               'Create User'
//             )}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CreateUserForm;


import React, { useState } from 'react';
import { createUser } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './CreateUserForm.css'; // Add this line to import the CSS file

const CreateUserForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    password: '',
    department: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user || !user.merchant_id) {
      setErrorMessage('Merchant ID not found');
      return;
    }

    // Set access level to 3
    const newUser = { ...formData, access_level: '3' };
    setLoading(true);

    try {
      const response = await createUser(newUser);
      if (response.success) {
        Swal.fire({
          icon: 'success',
          title: 'User Created',
          text: 'User created successfully',
        }).then(() => {
          navigate('/dashboard');
        });
      } else {
        setErrorMessage(response.error || 'Error creating user');
      }
    } catch (error) {
      console.error('Error creating user', error);
      setErrorMessage('Error creating user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className='col-lg-5 col-md-8 col-sm-10'>
          <h2 className='text-center mt-5'>Create User</h2>
          <form onSubmit={handleSubmit} className='mt-5'>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                name="department"
                placeholder="Department"
                value={formData.department}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Creating...
                </>
              ) : (
                'Create User'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUserForm;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { registerMerchant } from '../../services/api';

// const Register = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: ''
//   });
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await registerMerchant(formData);
//       if (response.success) {
//         login(response.user);
//         navigate('/login');
//       } else {
//         alert('Registration failed');
//       }
//     } catch (error) {
//       console.error('Error registering merchant', error);
//       alert('An error occurred during registration');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center mb-4">Register</h2>
//       <form onSubmit={handleSubmit} className="w-50 mx-auto">
//         <div className="mb-3">
//           <label htmlFor="username" className="form-label">Username</label>
//           <input
//             type="text"
//             id="username"
//             name="username"
//             className="form-control"
//             value={formData.username}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="email" className="form-label">Email</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             className="form-control"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="phone" className="form-label">Phone</label>
//           <input
//             type="text"
//             id="phone"
//             name="phone"
//             className="form-control"
//             value={formData.phone}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="password" className="form-label">Password</label>
//           <input
//             type="password"
//             id="password"
//             name="password"
//             className="form-control"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//         </div>
        
//         <button type="submit" className="btn btn-primary w-100">Register</button>
//       </form>
//     </div>
//   );
// };

// export default Register;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { registerMerchant } from '../../services/api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// const Register = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: ''
//   });
//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     setErrors({ ...errors, [e.target.name]: '' }); // Clear error message when user types
//   };

//   const validate = () => {
//     const newErrors = {};
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const usernameRegex = /^[a-zA-Z0-9_]{6,12}$/; // Alphanumeric and underscore, 6-12 characters
//     const phoneRegex = /^[0-9]{10,15}$/; // Digits only, 10-15 characters
//     const passwordMinLength = 8;

//     if (!usernameRegex.test(formData.username)) {
//       newErrors.username = 'Username must be 6-12 characters and can only contain letters, numbers, and underscores';
//     }
//     if (!emailRegex.test(formData.email)) {
//       newErrors.email = 'Invalid email format';
//     }
//     if (!phoneRegex.test(formData.phone)) {
//       newErrors.phone = 'Phone number must be 10-15 digits long';
//     }
//     if (formData.password.length < passwordMinLength) {
//       newErrors.password = `Password must be at least ${passwordMinLength} characters long`;
//     }

//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }

//     try {
//       const response = await registerMerchant(formData);
//       if (response.success) {
//         login(response.user);
//         navigate('/login');
//       } else {
//         alert('Registration failed');
//       }
//     } catch (error) {
//       console.error('Error registering merchant', error);
//       alert('An error occurred during registration');
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center mb-4">Register</h2>
//       <form onSubmit={handleSubmit} className="w-50 mx-auto">
//         <div className="mb-3">
//           <label htmlFor="username" className="form-label">Username</label>
//           <input
//             type="text"
//             id="username"
//             name="username"
//             className="form-control"
//             value={formData.username}
//             onChange={handleChange}
//             required
//           />
//           {errors.username && <div className="text-danger">{errors.username}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="email" className="form-label">Email</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             className="form-control"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//           {errors.email && <div className="text-danger">{errors.email}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="phone" className="form-label">Phone</label>
//           <input
//             type="text"
//             id="phone"
//             name="phone"
//             className="form-control"
//             value={formData.phone}
//             onChange={handleChange}
//             required
//           />
//           {errors.phone && <div className="text-danger">{errors.phone}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="password" className="form-label">Password</label>
//           <div className="input-group">
//             <input
//               type={showPassword ? "text" : "password"}
//               id="password"
//               name="password"
//               className="form-control"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//             <button
//               type="button"
//               className="btn btn-outline-secondary"
//               onClick={togglePasswordVisibility}
//             >
//               <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//             </button>
//           </div>
//           {errors.password && <div className="text-danger">{errors.password}</div>}
//         </div>
        
//         <button type="submit" className="btn btn-primary w-100">Register</button>
//       </form>
//     </div>
//   );
// };

// export default Register;




// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { registerMerchant } from '../../services/api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// const Register = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     phone: '',
//     password: '',
//     confirmPassword: ''
//   });
//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     setErrors({ ...errors, [e.target.name]: '' }); // Clear error message when user types
//   };

//   const validate = () => {
//     const newErrors = {};
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const usernameRegex = /^[a-zA-Z0-9_]{6,12}$/; // Alphanumeric and underscore, 6-12 characters
//     const phoneRegex = /^[0-9]{10,15}$/; // Digits only, 10-15 characters
//     const passwordMinLength = 8;

//     if (!usernameRegex.test(formData.username)) {
//       newErrors.username = 'Username must be 6-12 characters and can only contain letters, numbers, and underscores';
//     }
//     if (!emailRegex.test(formData.email)) {
//       newErrors.email = 'Invalid email format';
//     }
//     if (!phoneRegex.test(formData.phone)) {
//       newErrors.phone = 'Phone number must be 10-15 digits long';
//     }
//     if (formData.password.length < passwordMinLength) {
//       newErrors.password = `Password must be at least ${passwordMinLength} characters long`;
//     }
//     if (formData.password !== formData.confirmPassword) {
//       newErrors.confirmPassword = 'Passwords do not match';
//     }

//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }

//     try {
//       const response = await registerMerchant(formData);
//       if (response.success) {
//         login(response.user);
//         navigate('/login');
//       } else {
//         alert('Registration failed');
//       }
//     } catch (error) {
//       console.error('Error registering merchant', error);
//       alert('An error occurred during registration');
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center mb-4">Register</h2>
//       <form onSubmit={handleSubmit} className="w-50 mx-auto">
//         <div className="mb-3">
//           <label htmlFor="username" className="form-label">Username</label>
//           <input
//             type="text"
//             id="username"
//             name="username"
//             className="form-control"
//             value={formData.username}
//             onChange={handleChange}
//             required
//           />
//           {errors.username && <div className="text-danger">{errors.username}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="email" className="form-label">Email</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             className="form-control"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//           {errors.email && <div className="text-danger">{errors.email}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="phone" className="form-label">Phone</label>
//           <input
//             type="text"
//             id="phone"
//             name="phone"
//             className="form-control"
//             value={formData.phone}
//             onChange={handleChange}
//             required
//           />
//           {errors.phone && <div className="text-danger">{errors.phone}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="password" className="form-label">Password</label>
//           <div className="input-group">
//             <input
//               type={showPassword ? "text" : "password"}
//               id="password"
//               name="password"
//               className="form-control"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//             <button
//               type="button"
//               className="btn btn-outline-secondary"
//               onClick={togglePasswordVisibility}
//             >
//               <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//             </button>
//           </div>
//           {errors.password && <div className="text-danger">{errors.password}</div>}
//         </div>
//         <div className="mb-3">
//           <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
//           <div className="input-group">
//             <input
//               type={showConfirmPassword ? "text" : "password"}
//               id="confirmPassword"
//               name="confirmPassword"
//               className="form-control"
//               value={formData.confirmPassword}
//               onChange={handleChange}
//               required
//             />
//             <button
//               type="button"
//               className="btn btn-outline-secondary"
//               onClick={toggleConfirmPasswordVisibility}
//             >
//               <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
//             </button>
//           </div>
//           {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
//         </div>
//         <button type="submit" className="btn btn-primary w-100">Register</button>
//       </form>
//     </div>
//   );
// };

// export default Register;


import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { registerMerchant } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear error message when user types
  };

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9.]{6,15}$/; //  letters, digits, dots, 6-15 characters
    const phoneRegex = /^[0-9]{10,15}$/; // Digits only, 10-15 characters
    const passwordMinLength = 8;

    if (!usernameRegex.test(formData.username)) {
      newErrors.username = 'Username must be 6-15 characters and can only contain  letters, numbers, and dots.';
    }
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10-15 digits long';
    }
    if (formData.password.length < passwordMinLength) {
      newErrors.password = `Password must be at least ${passwordMinLength} characters long`;
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await registerMerchant(formData);
      if (response.success) {
        login(response.user);
        navigate('/login');
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      console.error('Error registering merchant', error);
      alert('An error occurred during registration');
    }finally {
      setLoading(false); // Stop loading after registration attempt
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="card p-4 shadow-sm">
            <h2 className="text-center mb-4">Register</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <div className="invalid-feedback">{errors.username}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                  {errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                  </button>
                  {errors.confirmPassword && <div className="invalid-feedback d-block">{errors.confirmPassword}</div>}
                </div>
              </div>
              {/* <button type="submit" className="btn btn-primary w-100">Register</button> */}
              {loading ? ( 
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <button type="submit" className="btn btn-primary w-100" disabled={loading}>Register</button>
              )}
            </form>
            <p>
              already register <NavLink to="/login">
signup
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginMerchant } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginMerchant({ username, password });
//       console.log("loginMerchant response:", response);
//       if (response && response.success) {
//         login(response.user);
//         navigate('/dashboard');
//       } else {
//         alert('Invalid credentials');
//       }
//     } catch (error) {
//       console.error('Error logging in', error);
//       alert('Error logging in');
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           placeholder="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginMerchant } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginMerchant({ username, password });
//       console.log("loginMerchant response:", response);
//       if (response && response.success) {
//         if (response.user && response.user.access_level) {
//           const { access_level, ...userData } = response.user;
//           login({ ...userData, access_level });
//         } else {
//           login({ ...response.user, access_level: 0 });
//         }
//         navigate('/dashboard');
//       } else {
//         alert('Invalid credentials');
//       }
//     } catch (error) {
//       console.error('Error logging in', error);
//       alert('Error logging in');
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           placeholder="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginMerchant } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginMerchant({ username, password });
//       console.log("loginMerchant response:", response);
//       if (response && response.success) {
//         const { user } = response;
//         login(user);
//         navigate('/dashboard');
//       } else {
//         alert('Invalid credentials');
//       }
//     } catch (error) {
//       console.error('Error logging in', error);
//       alert('Error logging in');
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           placeholder="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginMerchant } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginMerchant({ username, password });
//       console.log("loginMerchant response:", response);
//       if (response && response.success) {
//         if (response.user && response.user.access_level !== undefined) {
//           const { access_level, ...userData } = response.user;
//           login({ ...userData, access_level: String(access_level) });
//         } else {
//           login({ ...response.user, access_level: '1' });
//         }
//         navigate('/dashboard');
//       } else {
//         alert('Invalid credentials');
//       }
//     } catch (error) {
//       console.error('Error logging in', error);
//       alert('Error logging in');
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           placeholder="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginMerchant } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginMerchant({ username, password });
//       console.log("loginMerchant response:", response);
//       if (response && response.success) {
//         if (response.user && response.user.access_level !== undefined) {
//           const { access_level, ...userData } = response.user;
//           login({ ...userData, access_level: String(access_level) });
//         } else {
//           login({ ...response.user, access_level: '1' });
//         }
//         navigate('/dashboard');
//       } else {
//         alert('Invalid credentials');
//       }
//     } catch (error) {
//       console.error('Error logging in', error);
//       alert('Error logging in');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center">Login</h2>
//       <form onSubmit={handleSubmit} className="w-50 mx-auto">
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Username"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//           />
//         </div>
//         <div className="mb-3">
//           <input
//             type="password"
//             className="form-control"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//         </div>
//         <button type="submit" className="btn btn-primary w-100">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { loginMerchant } from '../../services/api';
// import { useAuth } from '../../contexts/AuthContext';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import Swal from 'sweetalert2';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const { login } = useAuth();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await loginMerchant({ username, password });
//       console.log("loginMerchant response:", response);
//       if (response && response.success) {
//         if (response.user && response.user.access_level !== undefined) {
//           const { access_level, ...userData } = response.user;
//           login({ ...userData, access_level: String(access_level) });
//         } else {
//           login({ ...response.user, access_level: '1' });
//         }
//         navigate('/dashboard');
//       } else {
//         if (response.message === 'Wrong username') {
//           Swal.fire({
//             icon: 'error',
//             title: 'Oops...',
//             text: 'Wrong username',
//           });
//         } else if (response.message === 'Wrong password') {
//           Swal.fire({
//             icon: 'error',
//             title: 'Oops...',
//             text: 'Wrong password',
//           });
//         } else {
//           Swal.fire({
//             icon: 'error',
//             title: 'Oops...',
//             text: 'Invalid credentials',
//           });
//         }
//       }
//     } catch (error) {
//       console.error('Error logging in', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Error logging in',
//       });
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center mb-4">Login</h2>
//       <div className='row justify-content-center'>
//         <div className='col-5'>

//         <div className="card p-4 mb-0 shadow-sm">
//       <form onSubmit={handleSubmit} className="w-50 mx-auto">
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Username"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             required
//           />
//         </div>
//         <div className="mb-3 input-group">
//           <input
//             type={showPassword ? "text" : "password"}
//             className="form-control"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           <button
//             type="button"
//             className="btn btn-outline-secondary"
//             onClick={togglePasswordVisibility}
//           >
//             <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//           </button>
//         </div>
//         <button type="submit" className="btn btn-primary w-100">Login</button>
//       </form>
//       </div>
//       </div>
//       </div>
//     </div>
//   ); 
// };

// export default Login;



import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { loginMerchant } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginMerchant({ username, password });
      // console.log("loginMerchant response:", response);
      if (response && response.success) {
        if (response.user && response.user.access_level !== undefined) {
          const { access_level, ...userData } = response.user;
          login({ ...userData, access_level: String(access_level) });
        } else {
          login({ ...response.user, access_level: '1' });
        }
        navigate('/dashboard');
      } else {
        if (response.message === 'Wrong username') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Wrong username',
          });
        } else if (response.message === 'Wrong password') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Wrong password',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid credentials',
          });
        }
      }
    } catch (error) {
      console.error('Error logging in', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error logging in',
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Login</h2>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-6 col-lg-4'>

          <div className="card p-4 mb-0 shadow-sm">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              <button type="submit" className="btn btn-primary w-100">Login</button>
              <NavLink to="/forgot-password" className="forgot-password-link">Forgot Password?</NavLink>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

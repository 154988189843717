// import React, { useState } from 'react';
// import { useContext } from 'react';
// import { useAuth } from '../../../contexts/AuthContext';
// // import LogoutButton from './LogoutButton';
// import Dropdown from 'react-bootstrap/Dropdown';
// // import img from './1.jpeg';

// const Navbar = ({ responsive }) => {
// //   const { loggedIn } = useContext(useAuth);
//   const [showMenu, setShowMenu] = useState(false);

//   return (
//     <nav className={`navbar navbar-expand-lg navbar-light bg-light ${responsive ? 'navbar-responsive' : ''}`}>
//       <div className="container-fluid">
//         <a className="navbar-brand" href="/dashboard">
//           {/* <img 
//             src={img} 
//             alt="Profile Avatar" 
//             className="rounded-circle mr-2" 
//             style={{ width: '30px', height: '30px' }}
//           /> */}
//           Dashboard
//         </a>
//         <div className={`d-flex align-items-center ${responsive ? 'flex-row-reverse' : ''}`}>
//           <Dropdown 
//             className="d-inline"
//             onMouseEnter={() => setShowMenu(true)}
//             onMouseLeave={() => setShowMenu(false)}
//             show={showMenu}
//           >
//             <div className="mx-4">
//               <div className="d-flex align-items-center">
//                 <img 
//                   src={''} 
//                   alt="Profile Avatar" 
//                   className="rounded-circle mr-2" 
//                   style={{ width: '30px', height: '30px' }}
//                 />
//                 <p className="mb-0">Username</p>
//               </div>
//             </div>
//             <Dropdown.Menu 
//               className={`dropdown-menu-left profile-menu ${showMenu && 'show'}`}
//             >
//               <Dropdown.Item href="#">Profile</Dropdown.Item>
//               <Dropdown.Item href="#">Logout</Dropdown.Item>
//               {/* {loggedIn && <Dropdown.Item>{<LogoutButton />}</Dropdown.Item>} */}
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from '../../../contexts/AuthContext';

const Navbar = ({ responsive }) => {
  const { user, logout } = useAuth();
  // console.log("user :" , user)
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = () => {
    logout();
    window.location.href = '/login'; 
  };

  const getUsernameInitial = (username) => {
    return username ? username.charAt(0).toUpperCase() : '';
  };

  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-light  sticky-top ${responsive ? 'navbar-responsive' : ''}`}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/dashboard">
          Dashboard
        </a>
        <div className={`d-flex align-items-center me-5 ${responsive ? 'flex-row-reverse' : ''}`}>
          {user && (
            <Dropdown 
              className="d-inline"
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
              show={showMenu}
            >
              <div className="mx-4 ">
                <div className="d-flex align-items-center">
                  <div 
                    className="rounded-circle mr-2 bg-primary text-white d-flex justify-content-center align-items-center" 
                    style={{ width: '30px', height: '30px' }}
                  >
                    {getUsernameInitial(user.username)}
                  </div>
                  <p className="mb-0 ms-2">{user.username}</p>
                </div>
              </div>
              <Dropdown.Menu className={`dropdown-menu-left profile-menu ${showMenu && 'show'}`}>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


// // import React from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import { useAuth } from '../../contexts/AuthContext';

// // const AdminDashboard = () => {
// //   const { logout } = useAuth();
// //   const navigate = useNavigate();

// //   const handleLogout = async () => {
// //     logout();
// //     navigate('/login');
// //   };

// //   return (
// //     <div>
// //       <h2>Admin Dashboard</h2>
// //       <button onClick={() => navigate('/create-user')}>Create User</button>
// //       <button onClick={() => navigate('/submit-candidate-form')}>Submit Candidate Form</button>
// //       <button onClick={() => navigate('/view-forms')}>View Candidate Forms</button>
// //       <button onClick={handleLogout}>Logout</button>
// //     </div>
// //   );
// // };

// // export default AdminDashboard;



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import {getMerchantData} from '../../services/api'
// const AdminDashboard = () => {
//   const { logout } = useAuth();
//   const navigate = useNavigate();
//   const [merchants, setMerchants] = useState([]);

//   useEffect(() => {
//     // Fetch merchants from backend
//     const fetchMerchants = async () => {
//       try {
//         const response = await getMerchantData(); 
//         if (!response.ok) {
//           throw new Error('Failed to fetch merchants');
//         }
//         const data = await response.json();
//         setMerchants(data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchMerchants();
//   }, []);

//   const handleLogout = async () => {
//     logout();
//     navigate('/login');
//   };

//   return (
//     <div>
//       <h2>Admin Dashboard</h2>
      
      
//       <div>
//         <h3>Merchants</h3>
//         <ul>
//           {merchants.map(merchant => (
//             <li key={merchant.id}>
//               {merchant.username} - {merchant.registration_date} - {merchant.active ? 'Active' : 'Inactive'}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default AdminDashboard;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { DataGrid } from '@mui/x-data-grid';
import { getAllMerchantData } from '../../services/api';

const AdminDashboard = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState([]);
  // console.log("merchants :" , merchants)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await getAllMerchantData(); 
        if (!response.success) { // Check if the response indicates success
          throw new Error('Failed to fetch merchants');
        }

        setMerchants(response.merchants); // Update state with the merchant data
        setLoading(false);

      } catch (error) {
        console.error(error);
        setError(error.message); // Set error state
        setLoading(false);
      }
    };
  
    fetchMerchants();
  }, []);
  
  const handleLogout = async () => {
    logout();
    navigate('/login');
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'trial_end_date', 
    headerName: 'Trial End Date',
     width: 150 ,
     renderCell: (params) => {
      const formattedDate = new Date(params.value).toLocaleString();
      return <span>{formattedDate}</span>;
    }},
    { field: 'plan_id', headerName: 'Plan ID', width: 120 },
  ];

  return (
    <div>
      <h2 className='text-center mt-5'>Admin Dashboard</h2>
    

      <div style={{ height: 600, width: '100%' }}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p> 
        ) : (
          <DataGrid
            rows={merchants.map((merchant, index) => ({ ...merchant, id: index }))}
            columns={columns}
            pageSize={5}
            checkboxSelection
          />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;

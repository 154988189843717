// import React from 'react';

// const MerchantUserDashboard = () => {
//   return (
//     <div>
//       <h1>Merchant User Dashboard</h1>
//     </div>
//   );
// };

// export default MerchantUserDashboard;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const MerchantUserDashboard = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    logout();
    navigate('/login');
  };

  return (
    <div>
      <h2>Merchant User Dashboard</h2>
     
    </div>
  );
};

export default MerchantUserDashboard;

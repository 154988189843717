// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faBriefcase, faRecycle, faMoneyBillAlt, faChartLine, faCog, faBars, faUser, faShareFromSquare, faFolderMinus } from '@fortawesome/free-solid-svg-icons';
// import './Sidebar.css';

// const Sidebar = ({ collapsed, darkTheme, toggleCollapse }) => {
//   return (
//     <div className={` sidebar${collapsed ? ' collapsed' : ''}`} style={{ height: '91vh', backgroundColor: ''  , borderRight:''}}>
//       {/* <div className="toggle-button" onClick={toggleCollapse}>
//         <FontAwesomeIcon icon={faBars} />
//       </div> */}
//       <ul className={`nav flex-column sidebar-list${collapsed ? ' collapsed' : ''}`}>
//         <li className="nav-item">
//           <NavLink exact to='/create-user' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//             <FontAwesomeIcon icon={faUser} />
//             {!collapsed && <p className=''>Create-user</p>}
//           </NavLink>
//         </li>
//         <li className="nav-item" >
//           <NavLink to='/submit-candidate-form' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//             <FontAwesomeIcon icon={faFolderMinus} />
//             {!collapsed && <p>Submit-candidate-form</p>}
//           </NavLink>
//         </li>
//         <li className="nav-item">
//           <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//             <FontAwesomeIcon icon={faShareFromSquare} />
//             {!collapsed && <p>View-forms</p>}
//           </NavLink>
//         </li>
//         {/* <li className="nav-item">
//           <NavLink to='/billing' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//             <FontAwesomeIcon icon={faMoneyBillAlt} />
//             {!collapsed && <p>Billing</p>}
//           </NavLink>
//         </li> */}
//         {/* <li className="nav-item">
//           <NavLink to='/activity' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//             <FontAwesomeIcon icon={faChartLine} />
//             {!collapsed && <p>Activity</p>}
//           </NavLink>
//         </li> */}
//         <li className="nav-item">
//           <NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//             <FontAwesomeIcon icon={faCog} />
//             {!collapsed && <p>Settings</p>}
//           </NavLink>
//         </li>
//       </ul>
//     </div>
//   );
// };

// export default Sidebar;



// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faFolderMinus, faShareFromSquare, faCog } from '@fortawesome/free-solid-svg-icons';
// import { useAuth } from '../../../contexts/AuthContext';
// import './Sidebar.css';

// const Sidebar = ({ collapsed, darkTheme, toggleCollapse }) => {
//   const { user } = useAuth();
//   console.log(user)

//   const renderMenuItems = () => {
//     if (user.role === 'superadmin') {
//       return (
//         <>
//           {/* <li className="nav-item">
//             <NavLink to='/submit-candidate-form' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faFolderMinus} />
//               {!collapsed && <p>Submit Candidate Form</p>}
//             </NavLink>
//           </li> */}
//           <li className="nav-item">
//             <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faShareFromSquare} />
//               {!collapsed && <p>View Forms</p>}
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink to='/settings' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faCog} />
//               {!collapsed && <p>Settings</p>}
//             </NavLink>
//           </li>
//         </>
//       );
//     } else if (user.role === 'merchant') {
//       return (
//         <>
//         <li className="nav-item">
//             <NavLink exact to='/create-user' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faUser} />
//               {!collapsed && <p>Create User</p>}
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink to='/submit-candidate-form' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faFolderMinus} />
//               {!collapsed && <p>Submit Candidate Form</p>}
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faShareFromSquare} />
//               {!collapsed && <p>View Forms</p>}
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink to='/settings' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faCog} />
//               {!collapsed && <p>Settings</p>}
//             </NavLink>
//           </li>
//         </>
//       );
//     } else if (user.role === 'merchant-user') {
//       return (
//         <>
//           <li className="nav-item">
//             <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faShareFromSquare} />
//               {!collapsed && <p>View Forms</p>}
//             </NavLink>
//           </li>
//         </>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className={`sidebar${collapsed ? ' collapsed' : ''}`} style={{ height: '91vh', backgroundColor: '', borderRight: '' }}>
//       <ul className={`nav flex-column sidebar-list${collapsed ? ' collapsed' : ''}`}>
//         {renderMenuItems()}
//       </ul>
//     </div>
//   );
// };

// export default Sidebar;



// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faFolderMinus, faShareFromSquare, faCog } from '@fortawesome/free-solid-svg-icons';
// import { useAuth } from '../../../contexts/AuthContext';
// import './Sidebar.css';

// const Sidebar = ({ collapsed, darkTheme, toggleCollapse }) => {
//   const { user } = useAuth();

//   const determineUserRole = (accessLevel) => {
//     if (accessLevel === "1") {
//       return "superadmin";
//     } else if (accessLevel === "2") {
//       return "merchant";
//     } else if (accessLevel === "3") {
//       return "merchant-user";
//     } else {
//       return "unknown";
//     }
//   };

//   const userRole = determineUserRole(user.access_level);

//   const renderMenuItems = () => {
//     if (userRole === 'superadmin') {
//       return (
//         <>
//            {/* <li className="nav-item">
//             <NavLink to='/submit-candidate-form' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faFolderMinus} />
//               {!collapsed && <p>Submit Candidate Form</p>}
//             </NavLink>
//           </li> */}
//           <li className="nav-item">
//             <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faShareFromSquare} />
//               {!collapsed && <p>View Forms</p>}
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faCog} />
//               {!collapsed && <p>Settings</p>}
//             </NavLink>
//           </li>
//         </>
//       );
//     } else if (userRole === 'merchant') {
//       return (
//         <>
//           <li className="nav-item">
//             <NavLink exact to='/create-user' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               {/* <FontAwesomeIcon icon={faUser} /> */}
//               {!collapsed && <p>Create User</p>}
//             </NavLink>
//           </li>
//           {/* <li className="nav-item">
//             <NavLink to='/submit-candidate-form' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               <FontAwesomeIcon icon={faFolderMinus} />
//               {!collapsed && <p>Submit Candidate Form</p>}
//             </NavLink>
//           </li> */}
//           <li className="nav-item">
//             <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               {/* <FontAwesomeIcon icon={faShareFromSquare} /> */}
//               {!collapsed && <p>View Forms</p>}
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               {/* <FontAwesomeIcon icon={faCog} /> */}
//               {!collapsed && <p>Settings</p>}
//             </NavLink>
//           </li>
//         </>
//       );
//     } else if (userRole === 'merchant-user') {
//       return (
//         <>
//           <li className="nav-item">
//             <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
//               {/* <FontAwesomeIcon icon={faShareFromSquare} /> */}
//               {!collapsed && <p>View Forms</p>}
//             </NavLink>
//           </li>
//         </>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className={`sidebar${collapsed ? ' collapsed' : ''} position-fixed`} style={{ height: '91vh', backgroundColor: '', borderRight: '' }}>
//       <ul className={`nav flex-column sidebar-list${collapsed ? ' collapsed' : ''}`}>
//         {renderMenuItems()}
//       </ul>
//     </div>
//   );
// };

// export default Sidebar;


import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFolderMinus, faShareFromSquare, faCog, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../contexts/AuthContext';
import './Sidebar.css';

const Sidebar = ({ collapsed, darkTheme, toggleCollapse }) => {
  const { user } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const determineUserRole = (accessLevel) => {
    if (accessLevel === "1") {
      return "superadmin";
    } else if (accessLevel === "2") {
      return "merchant";
    } else if (accessLevel === "3") {
      return "merchant-user";
    } else {
      return "unknown";
    }
  };

  const userRole = determineUserRole(user.access_level);

  const renderMenuItems = () => {
    if (userRole === 'superadmin') {
      return (
        <>
          <li className="nav-item">
            <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
              <FontAwesomeIcon icon={faShareFromSquare} />
              {!collapsed && <p>View Forms</p>}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
              <FontAwesomeIcon icon={faCog} />
              {!collapsed && <p>Settings</p>}
            </NavLink>
          </li>
        </>
      );
    } else if (userRole === 'merchant') {
      return (
        <>
          <li className="nav-item">
            <NavLink exact to='/create-user' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
              {!collapsed && <p>Create User</p>}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
              {!collapsed && <p>View Forms</p>}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
              {!collapsed && <p>Settings</p>}
            </NavLink>
          </li>
        </>
      );
    } else if (userRole === 'merchant-user') {
      return (
        <>
          <li className="nav-item">
            <NavLink to='/view-forms' style={{ textDecoration: 'none', color: 'black' }} activeClassName="active">
              {!collapsed && <p>View Forms</p>}
            </NavLink>
          </li>
        </>
      );
    }
    return null;
  };

  return (
    <div className={`sidebar${collapsed ? ' collapsed' : ''} position-fixed`} style={{ height: '91vh', backgroundColor: '', borderRight: '' }}>
      <button className="burger-menu" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
        <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} />
      </button>
      <ul className={`nav flex-column sidebar-list${collapsed ? ' collapsed' : ''} ${mobileMenuOpen ? 'open' : ''}`}>
        {renderMenuItems()}
      </ul>
    </div>
  );
};

export default Sidebar;

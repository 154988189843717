// import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:7000',
//   withCredentials: true,
// });

// export const registerMerchant = async (data) => {
//   try {
//     const response = await api.post('/auth/register', data);
//     console.log("registerMerchant response:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error registering merchant:", error);
//     throw error; 
//   }
// };

// export const loginMerchant = async (data) => {
//   try {
//     const response = await api.post('/auth/login', data);
//     console.log("loginMerchant response:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error logging in merchant:", error);
//     throw error;
//   }
// };

// export const logout = async () => {
//   try {
//     const response = await api.get('/auth/logout');
//     console.log("logout response:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error logging out:", error);
//     throw error;
//   }
// };



// export const createUser = async (formData) => {
//   try {
//     const response = await api.post('/user/create-user', formData);
//     console.log("Create-User response:", response.data);
//     return response.data; 
//   } catch (error) {
//     console.error("Error creating user:" , error)
//     throw error; 
//   }
// };




// export const submitCandidateForm = async (data, config) => {
//   try {
//     const response = await api.post('/candidate/submit-candidate-form', data, config);
//     console.log("submitCandidateForm response:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error submitting candidate form:", error);
//     throw error;
//   }
// };

// export const getCandidateForms = async () => {
//   try {
//     const response = await api.get('/candidate/get-candidate-forms');
//     console.log("getCandidateForms response:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error getting candidate forms:", error);
//     throw error;
//   }
// };

// export default api;


import axios from 'axios';

const api = axios.create({
  baseURL: 'https://online.systoolsacademy.com',
  // baseURL: 'http://localhost:7000',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

api.interceptors.request.use(
  config => {
    // Assuming you store the token in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


export const registerMerchant = async (data) => {
  try {
    const response = await api.post('/auth/register', data);
    // console.log("registerMerchant response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error registering merchant:", error);
    throw error;
  }
};

export const loginMerchant = async (data) => {
  try {
    const response = await api.post('/auth/login', data);
    // Assuming the response includes a token
    const { token } = response.data;
    localStorage.setItem('token', token);
    // console.log("loginMerchant response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error logging in merchant:", error.response ? error.response.data : error.message);
    throw error;
  }
};


export const logout = async () => {
  try {
    const response = await api.get('/auth/logout');
    // console.log("logout response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
};

export const createUser = async (formData) => {
  // console.log("formdata : " , formData)
  try {
    const response = await api.post('/user/create-user', formData);
    // console.log("Create-User response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};


export const submitCandidateForm = async (data) => {
  try {
    const response = await axios.post('https://online.systoolsacademy.com/candidate/submit-candidate-form', data);
    // console.log("submitCandidateForm response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error submitting candidate form:", error);
    throw error;
  }
};


export const getCandidateForms = async () => {
  try {
    const response = await api.get('/candidate/candidate-forms/');
    // console.log("getCandidateForms response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting candidate forms:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getDocument = async (filename) => {
  try {
      const response = await api.get(`/candidate/document/${filename}`, {
          responseType: 'blob'
      });
      // console.log("getDocument response:", response);
      const fileURL = URL.createObjectURL(response.data);
      return fileURL;
  } catch (error) {
      console.error("Error getting document:", error);
      throw error;
  }
};



export const getMerchantData = async (merchantId) => {
  try {
    const response = await api.get(`/auth/merchant/${merchantId}`);
    // console.log("getMerchantData response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching merchant data:", error);
    throw error;
  }
};

export const getAllMerchantData = async () => {
  try {
    const response = await api.get(`/auth/merchant/`);
    // console.log("getMerchantData response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching merchant data:", error);
    throw error;
  }
};  

export const getComment = async (id, token) => {
  try {
    const response = await api.get(`candidate/get-comment/${id}`, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addComment = async (id, merchantId, comment, token) => {
  try {
    const response = await api.post(`/candidate/add-comment/${id}`, { merchantId, comment }, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const forgotPassword = async ({ email }) => {
  const response = await axios.post('https://online.systoolsacademy.com/auth/forgot-password', { email });
  console.log('response :' , response)
  return response.data;
};


export default api;


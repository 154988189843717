// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';
// import AdminDashboard from './Dashboard/AdminDashboard';
// import MerchantDashboard from './Dashboard/MerchantDashboard';
// import MerchantUserDashboard from './Dashboard/MerchantUserDashboard';


// const ProtectedRoute = ({ component: Component }) => {
//     const { isAuthenticated, user } = useAuth();
  
//     console.log("ProtectedRoute isAuthenticated:", isAuthenticated());
//     console.log("ProtectedRoute user:", user);
  
//     if (!isAuthenticated()) {
//       console.log("User not authenticated. Redirecting to login.");
//       return <Navigate to="/login" />;
//     }
  
//     if (!user) {
//       console.log("User is not defined yet.")
//       return null; // Or a loading spinner
//     }
  
//     console.log("User access_level:", user.access_level);
  
//     switch (user.access_level) {
//       case '1':
//         return <AdminDashboard />;
//       case '2':
//         return <MerchantDashboard />;
//       case '3':
//         return <MerchantUserDashboard />;
//       default:
//         console.log("Rendering default component.");
//         return <Component />;
//     }
//   };
  

// export default ProtectedRoute;



// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';
// import AdminDashboard from './Dashboard/AdminDashboard';
// import MerchantDashboard from './Dashboard/MerchantDashboard';
// import MerchantUserDashboard from './Dashboard/MerchantUserDashboard';

// const ProtectedRoute = ({ component: Component }) => {
//   const { isAuthenticated, user } = useAuth();

//   if (!isAuthenticated()) {
//     return <Navigate to="/login" />;
//   }

//   if (!user) {
//     return null; // Or a loading spinner
//   }

//   switch (user.access_level) {
//     case '1':
//       return <AdminDashboard />;
//     case '2':
//       return <MerchantDashboard />;
//     case '3':
//       return <MerchantUserDashboard />;
//     default:
//       return <Component />;
//   }
// };

// export default ProtectedRoute;


import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Layout from './Layout/Layout';
const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (

    <>
    <Layout>
              <Component />
               </Layout>
   </>

  )
 
 
 
};

export default ProtectedRoute;
// // import React, { useContext, createContext, useState } from 'react';

// // const AuthContext = createContext();

// // export const useAuth = () => {
// //   return useContext(AuthContext);
// // };

// // export const AuthProvider = ({ children }) => {
// //   const [user, setUser] = useState(null);

// //   console.log("user :" , user)

// //   const login = (token) => {
// //     localStorage.setItem('token', token);
// //     setUser('user');
// //   };

// //   const logout = () => {
// //     localStorage.removeItem('token');
// //     setUser(null);
// //   };


  
// //   const isAuthenticated = () => {
// //     return user == null;
// //   };
// //   return (
// //     <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
// //           {children}
// //         </AuthContext.Provider>
// //   );
// // };



// // import React, { useContext, createContext, useState } from 'react';

// // const AuthContext = createContext();

// // export const AuthProvider = ({ children }) => {
// //   const [user, setUser] = useState(null);
// //   console.log("user :" , user)

// //   const login = (userData) => {
// //     localStorage.setItem('user', JSON.stringify(userData));
// //     setUser(userData);
// //   };

// //   const logout = () => {
// //     localStorage.removeItem('user');
// //     setUser(null);
// //   };

// //   const isAuthenticated = () => {
// //         return user !== null;
// //       };
// //   return (
// //     <AuthContext.Provider value={{ user, setUser, login, logout , isAuthenticated  }}> 
// //       {children}
// //     </AuthContext.Provider>
// //   );
// // };

// // export const useAuth = () => useContext(AuthContext);


// // import React, { useContext, createContext, useState, useEffect } from 'react';

// // const AuthContext = createContext();

// // export const AuthProvider = ({ children }) => {
// //   const [user, setUser] = useState(() => {
// //     const storedUser = localStorage.getItem('user');
// //     return storedUser ? JSON.parse(storedUser) : null;
// //   });

// //   useEffect(() => {
// //     if (user) {
// //       localStorage.setItem('user', JSON.stringify(user));
// //     } else {
// //       localStorage.removeItem('user');
// //     }
// //   }, [user]);

// //   const login = (userData) => {
// //     setUser(userData);
// //   };

// //   const logout = () => {
// //     setUser(null);
// //   };

// //   const isAuthenticated = () => {
// //     return user !== null;
// //   };

// //   return (
// //     <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
// //       {children}
// //     </AuthContext.Provider>
// //   );
// // };

// // export const useAuth = () => useContext(AuthContext);



// import React, { useContext, createContext, useState, useEffect } from 'react';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(() => {
//     const storedUser = localStorage.getItem('user');
//     return storedUser ? JSON.parse(storedUser) : null;
//   });

//   useEffect(() => {
//     if (user) {
//       localStorage.setItem('user', JSON.stringify(user));
//     } else {
//       localStorage.removeItem('user');
//     }
//   }, [user]);

//   const login = (userData) => {
//     setUser(userData);
//   };

//   const logout = () => {
//     setUser(null);
//   };

//   const isAuthenticated = () => {
//     return user !== null;
//   };

//   return (
//     <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);



// import React, { useContext, createContext, useState, useEffect } from 'react';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(() => {
//     const storedUser = localStorage.getItem('user');
//     return storedUser ? JSON.parse(storedUser) : null;
//   });

//   useEffect(() => {
//     if (user) {
//       localStorage.setItem('user', JSON.stringify(user));
//     } else {
//       localStorage.removeItem('user');
//     }
//   }, [user]);

//   const login = (userData) => {
//     setUser(userData);
//   };

//   const logout = () => {
//     setUser(null);
//   };

//   const isAuthenticated = () => {
//     return user !== null;
//   };

//   return (
//     <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);



import React, { useContext, createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    // console.log('Stored User:', storedUser); // Log the stored user data
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const login = (userData) => {
    // console.log('Login:', userData); // Log user data on login
    setUser(userData);
  };

  const logout = () => {
    // console.log('Logout'); // Log when user logs out
    setUser(null);
  };

  const isAuthenticated = () => {
    // console.log('IsAuthenticated:', user !== null); // Log authentication status
    return user !== null;
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// // import React from 'react';
// // import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// // import Login from './components/Auth/Login';
// // import Register from './components/Auth/Register';
// // import AdminDashboard from './components/Dashboard/AdminDashboard';
// // import MerchantDashboard from './components/Dashboard/MerchantDashboard';
// // import CandidateForm from './components/Forms/CandidateForm';
// // import FormList from './components/Forms/FormList';
// // import { AuthProvider, useAuth } from './contexts/AuthContext';

// // const App = () => {
// //   return (
// //     <AuthProvider>
// //       <Router>
// //         <Routes>
// //           <Route path="/login" element={<Login />} />
// //           <Route path="/register" element={<Register />} />
// //           <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
// //           <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
// //           <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
// //           <Route path="/" element={<Navigate to="/login" />} />
// //           <Route path="*" element={<Navigate to="/" />} />
// //         </Routes>
// //       </Router>
// //     </AuthProvider>
// //   );
// // };

// // const Dashboard = () => {
// //   const { user } = useAuth();
// //   if (!user) return <Navigate to="/login" />;
// //   return user.type === 'admin' ? <AdminDashboard /> : <MerchantDashboard />;
// // };

// // const ProtectedRoute = ({ component: Component }) => {
// //   const { isAuthenticated } = useAuth();
// //   return isAuthenticated() ? <Component /> : <Navigate to="/login" />;
// // };

// // export default App;


// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import AdminDashboard from './components/Dashboard/AdminDashboard';
// import MerchantDashboard from './components/Dashboard/MerchantDashboard';
// import CandidateForm from './components/Forms/CandidateForm';
// import FormList from './components/Forms/FormList';
// import CreateUserForm from './components/Forms/CreateUserForm';
// import { AuthProvider, useAuth } from './contexts/AuthContext';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
//           <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
//           <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
//           <Route path="/create-user" element={<ProtectedRoute component={CreateUserForm} />} />
//           <Route path="/" element={<Navigate to="/login" />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// };

// const Dashboard = () => {
//   const { user } = useAuth();
//   if (!user) return <Navigate to="/login" />;
//   return user.type === 'admin' ? <AdminDashboard /> : <MerchantDashboard />;
// };

// const ProtectedRoute = ({ component: Component }) => {
//   const { isAuthenticated } = useAuth();
//   return isAuthenticated() ? <Component /> : <Navigate to="/login" />;
// };


// export default App;


// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import AdminDashboard from './components/Dashboard/AdminDashboard';
// import MerchantDashboard from './components/Dashboard/MerchantDashboard';
// import MerchantUserDashboard from './components/Dashboard/MerchantUserDashboard'; 
// import CandidateForm from './components/Forms/CandidateForm';
// import FormList from './components/Forms/FormList';
// import CreateUserForm from './components/Forms/CreateUserForm';
// import Form from './components/Forms/Form';
// import { AuthProvider, useAuth } from './contexts/AuthContext';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<ProtectedRoute />} />
//           <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
//           <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
//           <Route path="/create-user" element={<ProtectedRoute component={CreateUserForm} />} />
//           <Route path="/form" element={<ProtectedRoute component={Form} />} />
//           <Route path="/" element={<Navigate to="/login" />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// };

// const ProtectedRoute = ({ component: Component }) => {
//   const { isAuthenticated } = useAuth();  
//   return isAuthenticated() ? <DashboardWrapper Component={Component} /> : <Navigate to="/login" />;
// };

// const DashboardWrapper = ({ Component }) => {
//   const { user } = useAuth();
//   if (!user) return null; 
//   if (user.type === 'admin') return <AdminDashboard />;
//   if (user.access_level === 'merchant') return <MerchantDashboard />;
//   if (user.access_level === 'merchant_created_user') return <MerchantUserDashboard />;
//   return null;
// };

// export default App;



// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import AdminDashboard from './components/Dashboard/AdminDashboard';
// import MerchantDashboard from './components/Dashboard/MerchantDashboard';
// import MerchantUserDashboard from './components/Dashboard/MerchantUserDashboard'; 
// import CandidateForm from './components/Forms/CandidateForm';
// import FormList from './components/Forms/FormList';
// import CreateUserForm from './components/Forms/CreateUserForm';
// import Form from './components/Forms/Form';
// import { AuthProvider, useAuth } from './contexts/AuthContext';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<ProtectedRoute component={AdminDashboard} />} />
//           <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
//           <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
//           <Route path="/create-user" element={<ProtectedRoute component={CreateUserForm} />} />
//           <Route path="/form" element={<ProtectedRoute component={Form} />} />
//           <Route path="/" element={<Navigate to="/login" />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// };

// const ProtectedRoute = ({ component: Component }) => {
//   const { isAuthenticated, user } = useAuth();
//   console.log("ProtectedRoute Component:", Component); // Debugging line
//   console.log("ProtectedRoute isAuthenticated:", isAuthenticated()); // Debugging line

//   if (!isAuthenticated()) {
//     return <Navigate to="/login" />;
//   }

//   if (!user) {
//     return null; // Or a loading spinner
//   }

//   console.log("User type:", user.type); // Debugging line
//   console.log("User access_level:", user.access_level); // Debugging line

//   if (user.access_level === '1') return <AdminDashboard />;
//   if (user.access_level === '2') return <MerchantDashboard />;
//   if (user.access_level === '3') return <MerchantUserDashboard />;

//   return <Component />;
// };


// export default App;



// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import AdminDashboard from './components/Dashboard/AdminDashboard';
// import MerchantDashboard from './components/Dashboard/MerchantDashboard';
// import MerchantUserDashboard from './components/Dashboard/MerchantUserDashboard'; 
// import CandidateForm from './components/Forms/CandidateForm';
// import FormList from './components/Forms/FormList';
// import CreateUserForm from './components/Forms/CreateUserForm';
// import Form from './components/Forms/Form';
// import { AuthProvider, useAuth } from './contexts/AuthContext';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<ProtectedRoute component={AdminDashboard} />} />
//           <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
//           <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
//           <Route path="/create-user" element={<ProtectedRoute component={CreateUserForm} />} />
//           <Route path="/form" element={<ProtectedRoute component={Form} />} />
//           <Route path="/" element={<Navigate to="/login" />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// };

// const ProtectedRoute = ({ component: Component }) => {
//   const { isAuthenticated, user } = useAuth();
//   console.log("ProtectedRoute Component:", Component); 
//   console.log("ProtectedRoute isAuthenticated:", isAuthenticated());

//   if (!isAuthenticated()) {
//     return <Navigate to="/login" />;
//   }

//   if (!user) {
//     return null;
//   }

//   console.log("User type:", user.type); 
//   console.log("User access_level:", user.access_level); 

//   if (user.access_level === '1') return <AdminDashboard />;
//   if (user.access_level === '2') return <MerchantDashboard />;
//   if (user.access_level === '3') return <MerchantUserDashboard />;

//   return <Component />;
// };

// export default App;



// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import AdminDashboard from './components/Dashboard/AdminDashboard';
// import MerchantDashboard from './components/Dashboard/MerchantDashboard';
// import MerchantUserDashboard from './components/Dashboard/MerchantUserDashboard'; 
// import CandidateForm from './components/Forms/CandidateForm';
// import FormList from './components/Forms/FormList';
// import CreateUserForm from './components/Forms/CreateUserForm';
// import Form from './components/Forms/Form';
// import { AuthProvider, useAuth } from './contexts/AuthContext';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<ProtectedRoute component={AdminDashboard} />} />
//           <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
//           <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
//           <Route path="/create-user" element={<ProtectedRoute component={CreateUserForm} />} />
//           <Route path="/form" element={<ProtectedRoute component={Form} />} />
//           <Route path="/" element={<Navigate to="/login" />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// };

// const ProtectedRoute = ({ component: Component }) => {
//   const { isAuthenticated, user } = useAuth();
//   console.log("ProtectedRoute Component:", Component); // Debugging line
//   console.log("ProtectedRoute isAuthenticated:", isAuthenticated()); // Debugging line

//   if (!isAuthenticated()) {
//     return <Navigate to="/login" />;
//   }

//   if (!user) {
//     return null; // Or a loading spinner
//   }

//   console.log("User type:", user.type); // Debugging line
//   console.log("User access_level:", user.access_level); // Debugging line

//   const accessLevel = String(user.access_level); // Ensure it's a string for comparison

//   if (accessLevel === '1') return <AdminDashboard />;
//   if (accessLevel === '2') return <MerchantDashboard />;
//   if (accessLevel === '3') return <MerchantUserDashboard />;

//   return <Component />;
// };

// export default App;



// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './components/Auth/Login';
// import Register from './components/Auth/Register';
// import AdminDashboard from './components/Dashboard/AdminDashboard';
// import CandidateForm from './components/Forms/CandidateForm';
// import FormList from './components/Forms/FormList';
// import CreateUserForm from './components/Forms/CreateUserForm';
// import Form from './components/Forms/Form';
// import { AuthProvider } from './contexts/AuthContext';
// import ProtectedRoute from './components/ProtectedRoute';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<ProtectedRoute Component={AdminDashboard} />} />
//           <Route path="/submit-candidate-form" element={<ProtectedRoute Component={CandidateForm} />} />
//           <Route path="/view-forms" element={<ProtectedRoute Component={FormList} />} />
//           <Route path="/create-user" element={<ProtectedRoute Component={CreateUserForm} />} />
//           {/* <Route path="/create-user" element={<CreateUserForm />} /> */}
//           <Route path="/form" element={<ProtectedRoute Component={Form} />} />
//           <Route path="/" element={<Navigate to="/login" />} />
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </AuthProvider>
//   );
// };

// export default App;




// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import MerchantDashboard from './components/Dashboard/MerchantDashboard';
import MerchantUserDashboard from './components/Dashboard/MerchantUserDashboard';
import CandidateForm from './components/Forms/CandidateForm';
import FormList from './components/Forms/FormList';
import CreateUserForm from './components/Forms/CreateUserForm';
import Form from './components/Forms/Form'; 
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout/Layout';
import Home from './components/Pages/Home';
import ForgotPassword from './components/Auth/Forgot';

const DashboardRoute = () => {
  const { user } = useAuth();

  switch (user.access_level) {
    case '1':
      return <AdminDashboard />;
    case '2':
      return <MerchantDashboard />;
    case '3':
      return <MerchantUserDashboard />;
    default:
      return <Navigate to="/login" />;
  }
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<ProtectedRoute component={DashboardRoute} />} />
          <Route path="/submit-candidate-form" element={<ProtectedRoute component={CandidateForm} />} />
          {/* <Route path="/submit-candidate-form" element={<CandidateForm />} /> */}
          <Route path="/view-forms" element={<ProtectedRoute component={FormList} />} />
          <Route path="/create-user" element={<ProtectedRoute component={CreateUserForm} />} />
          <Route path="/form" element={<Form />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;


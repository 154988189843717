// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';

// const MerchantDashboard = () => {
//   const { logout } = useAuth();
//   const navigate = useNavigate();

//   const handleLogout = async () => {
//     logout();
//     navigate('/login');
//   };

//   return (
//     <div>
//       <h2>Merchant Dashboard</h2>
//       <button onClick={() => navigate('/create-user')}>Create User</button>
//       <button onClick={() => navigate('/submit-candidate-form')}>Submit Candidate Form</button>
//       <button onClick={() => navigate('/view-forms')}>View Candidate Forms</button>
//       <button onClick={handleLogout}>Logout</button>
//     </div>
//   );
// };

// export default MerchantDashboard;



// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getMerchantData } from '../../services/api';

// const MerchantDashboard = () => {
//   const { user, logout } = useAuth();
//   const navigate = useNavigate();
//   const [qrCodeURL, setQrCodeURL] = useState('');

//   useEffect(() => {
//     const fetchMerchantData = async () => {
//       try {
//         const data = await getMerchantData(user.merchant_id);
//         if (data.success) {
//           setQrCodeURL(data.merchant.qr_code_url);
//         }
//       } catch (error) {
//         console.error('Error fetching merchant data:', error);
//       }
//     };

//     fetchMerchantData();
//   }, [user.merchant_id]);

//   const handleLogout = () => {
//     logout();
//     navigate('/login');
//   };

//   return (
//     <div>
//       <h2>Merchant Dashboard</h2>
//       <button onClick={() => navigate('/create-user')}>Create User</button>
//       <button onClick={() => navigate('/submit-candidate-form')}>Submit Candidate Form</button>
//       <button onClick={() => navigate('/view-forms')}>View Candidate Forms</button>
//       <button onClick={handleLogout}>Logout</button>
//       {qrCodeURL && (
//         <div>
//           <h3>Your QR Code</h3>
//           <img src={qrCodeURL} alt="Merchant QR Code" />
//         </div>
//       )}
//     </div>
//   );
// };

// export default MerchantDashboard;



// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getMerchantData } from '../../services/api';

// const MerchantDashboard = () => {
//   const { user, logout } = useAuth();
//   const navigate = useNavigate();
//   const [qrCodeURL, setQrCodeURL] = useState('');

//   useEffect(() => {
//     const fetchMerchantData = async () => {
//       try {
//         const data = await getMerchantData(user.merchant_id);
//         if (data.success) {
//           setQrCodeURL(data.merchant.qr_code_url);
//         }
//       } catch (error) {
//         console.error('Error fetching merchant data:', error);
//       }
//     };

//     fetchMerchantData();
//   }, [user.merchant_id]);

  
//   const handleDownloadQRCode = () => {
//     // Create an anchor element
//     const link = document.createElement('a');
//     link.href = qrCodeURL;
//     link.download = 'qr_code.png';
//     link.click();
//   };

//   return (
//     <div>
//       <h2 className='text-center'>Merchant Dashboard</h2>
//       <div className="row">
//       <div className='float-end'>
//       {qrCodeURL && (
//         <>
//         <h3 className=''>Your QR Code</h3>
//         <img src={qrCodeURL} alt="Merchant QR Code" />
//         <div className='mt-4'>
//         <button onClick={handleDownloadQRCode}>Download QR Code</button>
//         </div>
//         </>
//         )}
//         </div>
//   <div className="col-lg-6 my-3">
//     <div className="card card-body bg-primary text-white rounded-4 p-4 shadow">
//       <p className="fw-bold h3">Website Analytics</p>
//       <p>
//         See for yourself why more enterprises choose Email Migarator for their
//         needs.{" "}
//       </p>
//       <p className="h4 fw-bold pt-3">Trafic</p>
//       <div className="row">
//         <div className="col-lg-6 my-2">
//           <div className="d-flex">
//             <div className="flex-shrink-0">
//               <svg width={20} height={20}>
//                 <use xlinkHref="#icon-trophy" fill="#fff" />
//               </svg>
//             </div>
//             <div className="flex-grow-1 ms-3">
//               <p className="h5">Sessions</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 my-2">
//           <div className="d-flex">
//             <div className="flex-shrink-0">
//               <svg width={20} height={20}>
//                 <use xlinkHref="#icon-trophy" fill="#fff" />
//               </svg>
//             </div>
//             <div className="flex-grow-1 ms-3">
//               <p className="h5">Page Views</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 my-2">
//           <div className="d-flex">
//             <div className="flex-shrink-0">
//               <svg width={20} height={20}>
//                 <use xlinkHref="#icon-trophy" fill="#fff" />
//               </svg>
//             </div>
//             <div className="flex-grow-1 ms-3">
//               <p className="h5">Sessions</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 my-2">
//           <div className="d-flex">
//             <div className="flex-shrink-0">
//               <svg width={20} height={20}>
//                 <use xlinkHref="#icon-trophy" fill="#fff" />
//               </svg>
//             </div>
//             <div className="flex-grow-1 ms-3">
//               <p className="h5">Page Views</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-3 my-3">
//     <div className="card card-body rounded-4 p-4 shadow">
//       <p className="text-end fw-bold text-success mb-0">+18.2%</p>
//       <p className="fw-bold h5">Website Status</p>
//       <p className="fw-bold h2 text-primary">$ 42.5k</p>
//       <div className="d-flex justify-content-between">
//         <div className="order text-center text-lg-start my-3">
//           <p className=" h5 mb-1">Order</p>
//           <p className="fw-bold">62.2%</p>
//         </div>
//         <div className="Visits text-center text-lg-end my-3">
//           <p className="h5 mb-1">Visits</p>
//           <p className="fw-bold">62.2%</p>
//         </div>
//       </div>
//       <div className="progress shadow">
//         <div
//           className="progress-bar progress-bar-striped progress-bar-animated"
//           style={{ width: "70%" }}
//         />
//         <div
//           className="progress-bar progress-bar-striped bg-info progress-bar-animated"
//           style={{ width: "30%" }}
//         />
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-3 my-3">
//     <div className="card card-body rounded-4 p-4 shadow">
//       <p>
//         <svg width={30} height={30}>
//           <use xlinkHref="#icon-trophy" fill="#0d6efd" />
//         </svg>
//       </p>
//       <p className="fw-bold h4">97.5k</p>
//       <p>
//         See for yourself why more enterprises choose Email Migarator for their
//         needs.{" "}
//       </p>
//       <div className="progress-bar"></div>
//     </div>
//   </div>
//   <div className="col-lg-6 my-3">
//     <div className="card card-body rounded-4 p-4 shadow">
//       <p className="fw-bold h3">Earning Reports</p>
//       <div className="d-flex py-3 align-items-center">
//         <p className="h1">$468</p>&nbsp;
//         <p className="mb-0">
//           <span className="alert alert-danger p-1 m-1">+4.2%</span>
//         </p>
//       </div>
//       <div className="card card-body rounded-4 p-4 shadow">
//         <div className="row">
//           <div className="col-lg-6 my-2">
//             <div className="d-flex">
//               <div className="flex-shrink-0">
//                 <svg width={20} height={20}>
//                   <use xlinkHref="#icon-trophy" fill="#0d6efd" />
//                 </svg>
//               </div>
//               <div className="flex-grow-1 ms-3">
//                 <p className="h5">Sessions</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-6 my-2">
//             <div className="d-flex">
//               <div className="flex-shrink-0">
//                 <svg width={20} height={20}>
//                   <use xlinkHref="#icon-trophy" fill="#0d6efd" />
//                 </svg>
//               </div>
//               <div className="flex-grow-1 ms-3">
//                 <p className="h5">Page Views</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-6 my-2">
//             <div className="d-flex">
//               <div className="flex-shrink-0">
//                 <svg width={20} height={20}>
//                   <use xlinkHref="#icon-trophy" fill="#0d6efd" />
//                 </svg>
//               </div>
//               <div className="flex-grow-1 ms-3">
//                 <p className="h5">Sessions</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-6 my-2">
//             <div className="d-flex">
//               <div className="flex-shrink-0">
//                 <svg width={20} height={20}>
//                   <use xlinkHref="#icon-trophy" fill="#0d6efd" />
//                 </svg>
//               </div>
//               <div className="flex-grow-1 ms-3">
//                 <p className="h5">Page Views</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 my-3">
//     <div className="card card-body rounded-4 p-4 shadow">
//       <p className="fw-bold h3">Earning Reports</p>
//       <div className="d-flex py-3 align-items-center">
//         <p className="h1">$468</p>&nbsp;
//         <p className="mb-0">
//           <span className="alert alert-danger p-1 m-1">+4.2%</span>
//         </p>
//       </div>
//       <div className="progress my-3">
//         <div className="progress-bar bg-success" style={{ width: "50%" }}>
//           Free Space
//         </div>
//         <div className="progress-bar bg-warning" style={{ width: "30%" }}>
//           Warning
//         </div>
//         <div className="progress-bar bg-danger" style={{ width: "20%" }}>
//           Danger
//         </div>
//       </div>
//       <div className="progress my-2 shadow">
//         <div
//           className="progress-bar progress-bar-striped progress-bar-animated"
//           style={{ width: "80%" }}
//         />
//       </div>
//       <div className="progress my-2 shadow">
//         <div
//           className="progress-bar progress-bar-striped bg-warning progress-bar-animated"
//           style={{ width: "50%" }}
//         />
//       </div>
//       <div className="progress my-2 shadow">
//         <div
//           className="progress-bar progress-bar-striped bg-danger progress-bar-animated"
//           style={{ width: "30%" }}
//         />
//       </div>
//     </div>
//   </div>
// </div>

      
//     </div>
//   );
// };

// export default MerchantDashboard;




// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getMerchantData } from '../../services/api';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';

// const AnimatedSkeletonCard = () => {
//   return (
//     <Card variant="outlined">
//       <CardContent>
//         <Box sx={{ width: 300 }}>
//           <Skeleton />
//           <Skeleton animation="wave" />
//           <Skeleton animation={false} />
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// const MerchantDashboard = () => {
//   const { user, logout } = useAuth();
//   const navigate = useNavigate();
//   const [qrCodeURL, setQrCodeURL] = useState('');
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchMerchantData = async () => {
//       try {
//         const data = await getMerchantData(user.merchant_id);
//         if (data.success) {
//           setQrCodeURL(data.merchant.qr_code_url);
//         }
//       } catch (error) {
//         console.error('Error fetching merchant data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchMerchantData();
//   }, [user.merchant_id]);

  
//   const handleDownloadQRCode = () => {
//     // Create an anchor element
//     const link = document.createElement('a');
//     link.href = qrCodeURL;
//     link.download = 'qr_code.png';
//     link.click();
//   };

//   return (
//     <div>
//       <h2 className='text-center'>Merchant Dashboard</h2>
//       <div className="row">
//         <div className='float-end'>
//           {qrCodeURL && (
//             <>
//               <h3 className=''>Your QR Code</h3>
//               <img src={qrCodeURL} alt="Merchant QR Code" />
//               <div className='mt-4'>
//                 <button onClick={handleDownloadQRCode}>Download QR Code</button>
//               </div>
//             </>
//           )}
//         </div>
//         <div className="col-lg-6 my-3">
//           {loading ? (
//             <AnimatedSkeletonCard />
//           ) : (
//             <p variant="outlined">
//               <CardContent>
//                 {/* Content for the first card */}
//               </CardContent>
//             </p>
//           )}
//         </div>
//         <div className="col-lg-3 my-3">
//           {loading ? (
//             <AnimatedSkeletonCard />
//           ) : (
//             <p variant="outlined">
//               <CardContent>
//                 {/* Content for the second card */}
//               </CardContent>
//             </p>
//           )}
//         </div>
//         <div className="col-lg-3 my-3">
//           {loading ? (
//             <AnimatedSkeletonCard />
//           ) : (
//             <p variant="outlined">
//               <CardContent>
//                 {/* Content for the third card */}
//               </CardContent>
//             </p>
//           )}
//         </div>
//         {/* Add more card components as needed */}
//       </div>
//     </div>
//   );
// };

// export default MerchantDashboard;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getMerchantData } from '../../services/api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const AnimatedSkeletonCard = () => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </CardContent>
    </Card>
  );
};

const MerchantDashboard = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [qrCodeURL, setQrCodeURL] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const data = await getMerchantData(user.merchant_id);
        if (data.success) {
          setQrCodeURL(data.merchant.qr_code_url);
        }
      } catch (error) {
        console.error('Error fetching merchant data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMerchantData();
  }, [user.merchant_id]);

  const handleDownloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCodeURL;
    link.download = 'qr_code.png';
    link.click();
  };

  return (
    <Container>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {/* {loading ? (
            <AnimatedSkeletonCard />
          ) : (
            <Card variant="outlined">
              <CardContent>
        
              </CardContent>
            </Card>
          )} */}
        </Grid>
        <Grid item xs={12} md={3}>
          {/* {loading ? (
            <AnimatedSkeletonCard />
          ) : (
            <Card variant="outlined">
              <CardContent>
              
              </CardContent>
            </Card>
          )} */}
        </Grid>
        <Grid item xs={12} md={3}>
          {/* {loading ? (
            <AnimatedSkeletonCard />
          ) : (
            <Card variant="outlined">
              <CardContent>
          
              </CardContent>
            </Card>
          )} */}
        </Grid>
        <Grid item xs={12} className="text-center mt-4" style={{height:'860px'}}>
          {qrCodeURL && (
            <>
              <Typography variant="h5">Your QR Code</Typography>
              <img src={qrCodeURL} alt="Merchant QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
              <div>

              <Button variant="contained" color="primary" onClick={handleDownloadQRCode} className="mt-3">
                Download QR Code
              </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default MerchantDashboard;

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getCandidateForms, getDocument } from '../../services/api';

// const FormList = () => {
//   const navigate = useNavigate(); 
//   const { isAuthenticated } = useAuth();

//   const [forms, setForms] = useState([]);
//   const [selectedDocument, setSelectedDocument] = useState(null);

//   useEffect(() => {
//     const fetchForms = async () => {
//       try {
//         const response = await getCandidateForms();
//         console.log('getCandidateForms response:', response);
//         if (Array.isArray(response.data)) {
//           setForms(response.data);
//         } else {
//           console.error('Invalid response data:', response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching forms', error);
//       }
//     };
//     fetchForms();
//   }, []);

//   const handleViewDocument = async (documentName) => {
//     try {
//       console.log('Opening document:', documentName);
//       const response = await getDocument(documentName);
//       console.log("getDocument response:", response);
//       setSelectedDocument(response);
//     } catch (error) {
//       console.error("Error handling document:", error);
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Candidate Forms</h2>
//       <ul className="list-group">
//         {forms.map((form) => (
//           <li key={form.id} className="list-group-item">
//             <strong>Name:</strong> {form.name} <br />
//             <strong>Email:</strong> {form.email} <br />
//             <strong>Phone:</strong> {form.phone} <br />
//             <strong>Document:</strong> <button onClick={() => handleViewDocument(form.document)}>View Document</button>
//           </li>
//         ))}
//       </ul>
//       {selectedDocument && (
//         <iframe title="document" src={selectedDocument} width="100%" height="600px" />
//       )}
//     </div>
//   );
// };

// export default FormList;



// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getCandidateForms, getDocument, addComment } from '../../services/api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { DataGrid } from '@mui/x-data-grid';
// import { Button, FormControl, InputLabel, OutlinedInput, CircularProgress } from '@mui/material';

// const FormList = () => {
//   const navigate = useNavigate();
//   const { isAuthenticated } = useAuth();

//   const [forms, setForms] = useState([]);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const [selectedUserName, setSelectedUserName] = useState('');
//   const [viewingDocument, setViewingDocument] = useState(false);
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [commentInput, setCommentInput] = useState('');
//   const [comments, setComments] = useState('');

//   useEffect(() => {
//     const fetchForms = async () => {
//       try {
//         const response = await getCandidateForms();
//         console.log('getCandidateForms response:', response);
//         if (Array.isArray(response.data)) {
//           setForms(response.data);
//         } else {
//           console.error('Invalid response data:', response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching forms', error);
//       }
//     };
//     fetchForms();
//   }, []);

//   const handleViewDocument = async (documentName, userName) => {
//     try {
//       console.log('Opening document:', documentName);
//       setPdfLoading(true);
//       const response = await getDocument(documentName);
//       console.log("getDocument response:", response);
//       setSelectedDocument(response);
//       setSelectedUserName(userName);
//       setViewingDocument(true);
//       setPdfLoading(false);
//     } catch (error) {
//       console.error("Error handling document:", error);
//       setPdfLoading(false);
//     }
//   };

//   const handleBack = () => {
//     setViewingDocument(false);
//     setSelectedDocument(null);
//     setSelectedUserName('');
//     setComments('');
//     setCommentInput('');
//   };

//   const handleCommentChange = (event) => {
//     setCommentInput(event.target.value);
//   };

//   const handleAddComment = async (document) => {
//     if (!commentInput) return;
//     try {
//       const response = await addComment(document, commentInput);
//       if (response.data.success) {
//         setComments(commentInput);
//         setCommentInput('');
//       } else {
//         console.error("Error adding comment:", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error adding comment:", error);
//     }
//   };

//   const columns = [
//     { field: 'id', headerName: '#', width: 90 },
//     { field: 'name', headerName: 'Name', width: 150 },
//     { field: 'document', headerName: 'File', width: 150 },
//     {
//       field: 'preview',
//       headerName: 'Preview',
//       width: 90,
//       renderCell: (params) => (
//         <Button onClick={() => handleViewDocument(params.row.document, params.row.name)}>
//           <FontAwesomeIcon icon={faEye} />
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <div className="container mt-5">
//       {!viewingDocument ? (
//         <>
//           <h2>Candidate Forms</h2>
//           <div style={{ height: 400, width: '100%' }}>
//             <DataGrid
//               rows={forms}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
//               checkboxSelection
//               disableSelectionOnClick
//             />
//           </div>
//         </>
//       ) : (
//         <div>
//           <Button onClick={handleBack} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
//             Back
//           </Button>
//           <h3>{selectedUserName}</h3>
//           <div style={{ marginTop: 16 }}>
//             {pdfLoading ? (
//               <div className='text-center'>
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 {selectedDocument && (
//                   <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
//                 )}
//               </>
//             )}
//           </div>
//           <div style={{ marginTop: 16 }}>
//             <FormControl variant="outlined" fullWidth>
//               <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
//               <OutlinedInput
//                 id="comment-textarea"
//                 multiline
//                 rows={4}
//                 value={commentInput}
//                 onChange={handleCommentChange}
//               />
//             </FormControl>
//             <Button variant="contained" color="primary" onClick={() => handleAddComment(selectedDocument)} style={{ marginTop: 16 }}>
//               Add Comment
//             </Button>
//           </div>
//           <div style={{ marginTop: 16, maxHeight: '300px', overflowY: 'auto' }}>
//             <p>{comments}</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FormList;



// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getCandidateForms, getDocument, addComment, getComment } from '../../services/api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { DataGrid } from '@mui/x-data-grid';
// import { Button, FormControl, InputLabel, OutlinedInput, CircularProgress, Typography } from '@mui/material';

// const FormList = () => {
//   const navigate = useNavigate();
//   const { isAuthenticated } = useAuth();

//   const [forms, setForms] = useState([]);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const [selectedUserName, setSelectedUserName] = useState('');
//   const [viewingDocument, setViewingDocument] = useState(false);
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [commentInput, setCommentInput] = useState('');
//   const [comments, setComments] = useState({});

//   useEffect(() => {
//     const fetchForms = async () => {
//       try {
//         const response = await getCandidateForms();
//         console.log('getCandidateForms response:', response);
//         if (Array.isArray(response.data)) {
//           setForms(response.data);
//           const commentPromises = response.data.map(async (form) => {
//             const comment = await getComment(form);
//             return { ...form, comment };
//           });
//           const formsWithComments = await Promise.all(commentPromises);
//           setForms(formsWithComments);
//         } else {
//           console.error('Invalid response data:', response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching forms', error);
//       }
//     };
//     fetchForms();
//   }, []);

//   const handleViewDocument = async (documentName, userName) => {
//     try {
//       console.log('Opening document:', documentName);
//       setPdfLoading(true);
//       const response = await getDocument(documentName);
//       console.log("getDocument response:", response);
//       setSelectedDocument({ documentName, fileURL: response });
//       setSelectedUserName(userName);
//       setViewingDocument(true);
//       setPdfLoading(false);
//     } catch (error) {
//       console.error("Error handling document:", error);
//       setPdfLoading(false);
//     }
//   };

//   const handleBack = () => {
//     setViewingDocument(false);
//     setSelectedDocument(null);
//     setSelectedUserName('');
//     setComments('');
//     setCommentInput('');
//   };

//   const handleCommentChange = (event) => {
//     setCommentInput(event.target.value);
//   };

//   const handleAddComment = async () => {
//     if (!commentInput) return;
//     try {
//       const response = await addComment(selectedDocument.documentName, commentInput);
//       if (response.success) {
//         setComments(prevComments => ({
//           ...prevComments,
//           [selectedDocument.documentName]: commentInput,
//         }));
//         setCommentInput('');
//         const updatedForms = forms.map((form) => {
//           if (form.document === selectedDocument.documentName) {
//             return { ...form, comment: commentInput };
//           }
//           return form;
//         });
//         setForms(updatedForms);
//       } else {
//         console.error("Error adding comment:", response.message);
//       }
//     } catch (error) {
//       console.error("Error adding comment:", error);
//     }
//   };

//   const columns = [
//     { field: 'id', headerName: 'ID', width: 90 },
//     { field: 'name', headerName: 'Name', width: 150 },
//     { field: 'document', headerName: 'File', width: 150 },
//     { field: 'comment', headerName: 'Comment', width: 250 },
//     {
//       field: 'preview',
//       headerName: 'Preview',
//       width: 90,
//       renderCell: (params) => (
//         <Button onClick={() => handleViewDocument(params.row.document, params.row.name)}>
//           <FontAwesomeIcon icon={faEye} />
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <div className="container mt-5">
//       {!viewingDocument ? (
//         <>
//           <h2>Candidate Forms</h2>
//           <div style={{ height: 400, width: '100%' }}>
//             <DataGrid
//               rows={forms}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
             
//             />
//           </div>
//         </>
//       ) : (
//         <div>
//           <Button onClick={handleBack} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
//             Back
//           </Button>
//           <h3 className='text-center'>{selectedUserName}</h3>
//           <div style={{ marginTop: 16 }}>
//             {pdfLoading ? (
//               <div className='text-center'>
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 {selectedDocument && (
//                   <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
//                 )}
//               </>
//             )}
//           </div>
//           <div style={{ marginTop: 16 }}>
//             <FormControl variant="outlined" fullWidth>
//               <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
//               <OutlinedInput
//                 id="comment-textarea"
//                 multiline
//                 rows={4}
//                 value={commentInput}
//                 onChange={handleCommentChange}
//               />
//             </FormControl>
//             <Button variant="contained" color="primary" onClick={handleAddComment} style={{ marginTop: 16 }}>
//               Add Comment
//             </Button>
//           </div>
//           <div style={{ marginTop: 16, maxHeight: '300px', overflowY: 'auto' }}>
//             <Typography variant="body1">{comments[selectedDocument.documentName]}</Typography>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FormList;


// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext'; // Assuming AuthContext provides merchantId
// import { getCandidateForms, getDocument, addComment, getComment } from '../../services/api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { DataGrid } from '@mui/x-data-grid';
// import { Button, FormControl, InputLabel, OutlinedInput, CircularProgress, Typography } from '@mui/material';
// import Swal from 'sweetalert2';

// const FormList = ({ sendDataToApi }) => {
//   const navigate = useNavigate();
//   const { token, user } = useAuth();
// const merchantId = user ? user.merchant_id : null;
// console.log("merchantID :" , merchantId)

//   const [forms, setForms] = useState([]);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const [selectedUserName, setSelectedUserName] = useState('');
//   const [viewingDocument, setViewingDocument] = useState(false);
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [commentInput, setCommentInput] = useState('');
//   const [comments, setComments] = useState({});

//   useEffect(() => {
//     const fetchForms = async () => {
//       try {
//         const response = await getCandidateForms(token);
//         if (Array.isArray(response.data)) {
//           setForms(response.data);
//           const commentPromises = response.data.map(async (form) => {
//             const comment = await getComment(form.id, token);
//             return { ...form, comment };
//           });
//           const formsWithComments = await Promise.all(commentPromises);
//           setForms(formsWithComments);
//         } else {
//           console.error('Invalid response data:', response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching forms', error);
//       }
//     };
//     fetchForms();
//   }, [token]);

//   const handleViewDocument = async (documentName, userName, userId) => {
//     try {
//       setPdfLoading(true);
//       const response = await getDocument(documentName, token);
//       setSelectedDocument({ documentName, fileURL: response, userId });
//       setSelectedUserName(userName);
//       setViewingDocument(true);
//       setPdfLoading(false);
//     } catch (error) {
//       console.error("Error handling document:", error);
//       setPdfLoading(false);
//     }
//   };

//   const handleBack = () => {
//     setViewingDocument(false);
//     setSelectedDocument(null);
//     setSelectedUserName('');
//     setComments({});
//     setCommentInput('');
//   };

//   const handleCommentChange = (event) => {
//     setCommentInput(event.target.value);
//   };

//   const handleAddComment = async () => {
//     if (!commentInput || !selectedDocument) return;
//     try {
//       console.log('Selected Document ID:', selectedDocument.userId); // Verify document ID

//       const payload = {
//         merchantId: merchantId, // Verify if merchantId is correctly retrieved from useAuth
//         comment: commentInput
//       };
//       console.log('Request Payload:', payload); // Verify payload format

//       const response = await addComment(selectedDocument.userId, merchantId, commentInput, token);
//       console.log('Response:', response); // Verify response from the backend

//       if (response.success) {
//         setComments(prevComments => ({
//           ...prevComments,
//           [selectedDocument.documentName]: commentInput,
//         }));
//         setCommentInput('');
//         const updatedForms = forms.map((form) => {
//           if (form.id === selectedDocument.userId) { // Assuming 'id' represents the document ID
//             return { ...form, comment: commentInput };
//           }
//           return form;
//         });
//         setForms(updatedForms);
//         if (sendDataToApi && typeof sendDataToApi === 'function') {
//           sendDataToApi(commentInput);
//         } else {
//           console.warn('sendDataToApi is not a function or is not defined');
//         }
//         Swal.fire({
//           icon: 'success',
//           title: 'Comment Added!',
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       } else {
//         console.error("Error adding comment:", response.message);
//       }
//     } catch (error) {
//       console.error("Error adding comment:", error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to add comment',
//       });
//     }
//   };
  

//   const columns = [
//     { field: 'id', headerName: 'ID', width: 90 },
//     { field: 'name', headerName: 'Name', width: 150 },
//     { field: 'document', headerName: 'File', width: 150 },
//     { field: 'comment', headerName: 'Comment', width: 250 },
//    { field: 'created_at', 
//     headerName: 'Date', 
//     width: 250,
//     renderCell: (params) => {
//       // Parse the timestamp and format it
//       const formattedDate = new Date(params.value).toLocaleString();
//       return <span>{formattedDate}</span>;
//     }
//   },
//     {
//       field: 'preview',
//       headerName: 'Preview',
//       width: 90,
//       renderCell: (params) => (
//         <Button onClick={() => handleViewDocument(params.row.document, params.row.name, params.row.id)}>
//           <FontAwesomeIcon icon={faEye} />
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <div className="container mt-5">
//       {!viewingDocument ? (
//         <>
//           <h2>Candidate Forms</h2>
//           <div style={{ height: 400, width: '100%' }}>
//             <DataGrid
//               rows={forms}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
//             />
//           </div>
//         </>
//       ) : (
//         <div>
//           <Button onClick={handleBack} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
//             Back
//           </Button>
//           <h3 className='text-center'>{selectedUserName}</h3>
//           <div style={{ marginTop: 16 }}>
//             {pdfLoading ? (
//               <div className='text-center'>
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 {selectedDocument && (
//                   <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
//                 )}
//               </>
//             )}
//           </div>
//           <div style={{ marginTop: 16 }}>
//             <FormControl variant="outlined" fullWidth>
//               <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
//               <OutlinedInput
//                 id="comment-textarea"
//                 multiline
//                 rows={4}
//                 value={commentInput}
//                 onChange={handleCommentChange}
//               />
//             </FormControl>
//             <Button variant="contained" color="primary" onClick={handleAddComment} style={{ marginTop: 16 }}>
//               Add Comment
//             </Button>
//           </div>
//           <div style={{ marginTop: 16, maxHeight: '300px', overflowY: 'auto' }}>
//             <Typography variant="body1">{comments[selectedDocument.documentid]}</Typography>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };


// export default FormList;



// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
// import { getCandidateForms, getDocument, addComment, getComment } from '../../services/api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { DataGrid } from '@mui/x-data-grid';
// import { Button, CircularProgress, FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material';
// import Skeleton from '@mui/material/Skeleton';
// import Swal from 'sweetalert2';

// const FormList = ({ sendDataToApi }) => {
//   const navigate = useNavigate();
//   const { token, user } = useAuth();
//   const merchantId = user ? user.merchant_id : null;

//   const [forms, setForms] = useState([]);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const [selectedUserName, setSelectedUserName] = useState('');
//   const [viewingDocument, setViewingDocument] = useState(false);
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [commentInput, setCommentInput] = useState('');
//   const [comments, setComments] = useState({});
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchForms = async () => {
//       try {
//         const response = await getCandidateForms(token);
//         if (Array.isArray(response.data)) {
//           setForms(response.data);
//           const commentPromises = response.data.map(async (form) => {
//             const comment = await getComment(form.id, token);
//             return { ...form, comment };
//           });
//           const formsWithComments = await Promise.all(commentPromises);
//           setForms(formsWithComments);
//         } else {
//           console.error('Invalid response data:', response.data);
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching forms', error);
//         setLoading(false);
//       }
//     };
//     fetchForms();
//   }, [token]);

//   const handleViewDocument = async (documentName, userName, userId) => {
//     try {
//       setPdfLoading(true);
//       const response = await getDocument(documentName, token);
//       setSelectedDocument({ documentName, fileURL: response, userId });
//       setSelectedUserName(userName);
//       setViewingDocument(true);
//       setPdfLoading(false);
//     } catch (error) {
//       console.error("Error handling document:", error);
//       setPdfLoading(false);
//     }
//   };

//   const handleBack = () => {
//     setViewingDocument(false);
//     setSelectedDocument(null);
//     setSelectedUserName('');
//     setComments({});
//     setCommentInput('');
//   };

//   const handleCommentChange = (event) => {
//     setCommentInput(event.target.value);
//   };

//   const handleAddComment = async () => {
//     if (!commentInput || !selectedDocument) return;
//     try {
//       console.log('Selected Document ID:', selectedDocument.userId); 

//       const payload = {
//         merchantId: merchantId,
//         comment: commentInput
//       };
//       console.log('Request Payload:', payload); 

//       const response = await addComment(selectedDocument.userId, merchantId, commentInput, token);
//       console.log('Response:', response); 

//       if (response.success) {
//         setComments(prevComments => ({
//           ...prevComments,
//           [selectedDocument.documentName]: commentInput,
//         }));
//         setCommentInput('');
//         const updatedForms = forms.map((form) => {
//           if (form.id === selectedDocument.userId) {
//             return { ...form, comment: commentInput };
//           }
//           return form;
//         });
//         setForms(updatedForms);
//         if (sendDataToApi && typeof sendDataToApi === 'function') {
//           sendDataToApi(commentInput);
//         } else {
//           console.warn('sendDataToApi is not a function or is not defined');
//         }
//         Swal.fire({
//           icon: 'success',
//           title: 'Comment Added!',
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       } else {
//         console.error("Error adding comment:", response.message);
//       }
//     } catch (error) {
//       console.error("Error adding comment:", error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to add comment',
//       });
//     }
//   };
  


//   const columns = [
//     { field: 'id', headerName: 'ID', width: 90 },
//     { field: 'name', headerName: 'Name', width: 150 },
//     { field: 'original_filename', headerName: 'Resume', width: 150 },
//     { field: 'comment', headerName: 'Comment', width: 250 },
//    { field: 'created_at', 
//     headerName: 'Date', 
//     width: 250,
//     renderCell: (params) => {
//       const formattedDate = new Date(params.value).toLocaleString();
//       return <span>{formattedDate}</span>;
//     }
//   },
//     {
//       field: 'preview',
//       headerName: 'Preview',
//       width: 90,
//       renderCell: (params) => (
//         <Button onClick={() => handleViewDocument(params.row.document, params.row.name, params.row.id)}>
//           <FontAwesomeIcon icon={faEye} />
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <div className="row">
//       {!viewingDocument ? (
       
//         <>
//           <h2 className='text-center fw-bold'>Candidate Resume</h2>
//           {loading ? (
//             <>

// <div className='mt-2'>
// <Skeleton animation="wave"  sx={{height:50}}/>
// </div>
// <div className='mt-2'>
// <Skeleton animation="wave"  />
// </div>
// <div className='mt-2'>
// <Skeleton animation="wave"  />
// </div>
// <div className='mt-2'>
// <Skeleton animation="wave"  />
// </div>
// <div className='mt-2'>
// <Skeleton animation="wave"  />
// </div>

//             </>
//           ) : (
//             <div className="mt-5" style={{ height: 570, width: '100%' }}>
//               <DataGrid
//                 rows={forms}
//                 columns={columns}
//                 pageSize={5}
//                 rowsPerPageOptions={[5]}
//               />
//             </div>
//           )}
//         </>
//       ) : (
//         <div>
//           <Button onClick={handleBack} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
//             Back
//           </Button>
//           <h3 className='text-center'>{selectedUserName}</h3>
//           <div style={{ marginTop: 16 }}>
//             {pdfLoading ? (
//               <div className='text-center'>
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 {selectedDocument && (
//                   <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
//                 )}
//               </>
//             )}
//           </div>
//           <div style={{ marginTop: 16 }}>
//             <FormControl variant="outlined" fullWidth>
//               <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
//               <OutlinedInput
//                 id="comment-textarea"
//                 multiline
//                 rows={4}
//                 value={commentInput}
//                 onChange={handleCommentChange}
//               />
//             </FormControl>
//             <Button variant="contained" color="primary" onClick={handleAddComment} style={{ marginTop: 16 }}>
//               Add Comment
//             </Button>
//           </div>
//           <div style={{ marginTop: 16, maxHeight: '300px', overflowY: 'auto' }}>
//             <Typography variant="body1">{comments[selectedDocument.documentid]}</Typography>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FormList;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getCandidateForms, getDocument, addComment, getComment } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress, FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Swal from 'sweetalert2';

const FormList = ({ sendDataToApi }) => {
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const merchantId = user ? user.merchant_id : null;

  const [forms, setForms] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [viewingDocument, setViewingDocument] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDocument, setLoadingDocument] = useState(null); // New state for tracking loading status of each document
  const [commentInput, setCommentInput] = useState('');
  const [comments, setComments] = useState({});

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await getCandidateForms(token);
        if (Array.isArray(response.data)) {
          setForms(response.data);
          const commentPromises = response.data.map(async (form) => {
            const comment = await getComment(form.id, token);
            return { ...form, comment };
          });
          const formsWithComments = await Promise.all(commentPromises);
          setForms(formsWithComments);
        } else {
          console.error('Invalid response data:', response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching forms', error); 
        setLoading(false);
      }
    };
    fetchForms();
  }, [token]);

  const handleViewDocument = async (documentName, userName, userId) => {
    try {
      setLoadingDocument(userId); // Set the loading state for the document being opened
      const response = await getDocument(documentName, token);
      setSelectedDocument({ documentName, fileURL: response, userId });
      setSelectedUserName(userName);
      setViewingDocument(true);
      setLoadingDocument(null); // Reset the loading state once the document is loaded
    } catch (error) {
      console.error("Error handling document:", error);
      setLoadingDocument(null); // Reset the loading state in case of error
    }
  };

  const handleBack = () => {
    setViewingDocument(false);
    setSelectedDocument(null);
    setSelectedUserName('');
    setComments({});
    setCommentInput('');
  };

  const handleCommentChange = (event) => {
    setCommentInput(event.target.value);
  };

  const handleAddComment = async () => {
    if (!commentInput || !selectedDocument) return;
    try {
      const payload = {
        merchantId: merchantId,
        comment: commentInput
      };
      const response = await addComment(selectedDocument.userId, merchantId, commentInput, token);
      if (response.success) {
        setComments(prevComments => ({
          ...prevComments,
          [selectedDocument.documentName]: commentInput,
        }));
        setCommentInput('');
        const updatedForms = forms.map((form) => {
          if (form.id === selectedDocument.userId) {
            return { ...form, comment: commentInput };
          }
          return form;
        });
        setForms(updatedForms);
        if (sendDataToApi && typeof sendDataToApi === 'function') {
          sendDataToApi(commentInput);
        } else {
          console.warn('sendDataToApi is not a function or is not defined');
        }
        Swal.fire({
          icon: 'success',
          title: 'Comment Added!',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        console.error("Error adding comment:", response.message);
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add comment',
      });
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone', headerName: 'Phone', width: 250 },
    { field: 'original_filename', headerName: 'Resume', width: 150 },
    { field: 'comment', headerName: 'Comment', width: 250 },
   { field: 'created_at', 
    headerName: 'Date', 
    width: 250,
    renderCell: (params) => {
      const formattedDate = new Date(params.value).toLocaleString();
      return <span>{formattedDate}</span>;
    }
  },
    {
      field: 'preview',
      headerName: 'Preview',
      width: 90,
      renderCell: (params) => (
        <Button onClick={() => handleViewDocument(params.row.document, params.row.name, params.row.id)}>
          {loadingDocument === params.row.id ? (
            <CircularProgress size={24} />
          ) : (
            <FontAwesomeIcon icon={faEye} />
          )}
        </Button>
      ),
    },
  ];

  return (
    <div className="row">
      {!viewingDocument ? (
       
        <>
          <h2 className='text-center fw-bold'>Candidate Resume</h2>
          {loading ? (
            <>
              <div className='mt-2'>
                <Skeleton animation="wave"  sx={{height:50}}/>
              </div>
              <div className='mt-2'>
                <Skeleton animation="wave"  />
              </div>
              <div className='mt-2'>
                <Skeleton animation="wave"  />
              </div>
              <div className='mt-2'>
                <Skeleton animation="wave"  />
              </div>
              <div className='mt-2'>
                <Skeleton animation="wave"  />
              </div>
            </>
          ) : (
            <div className="mt-5" style={{ height: 570, width: '100%' }}>
              <DataGrid
                rows={forms}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </div>
          )}
        </>
      ) : (
        <div>
          <Button onClick={handleBack} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
            Back
          </Button>
          <h3 className='text-center'>{selectedUserName}</h3>
          <div style={{ marginTop: 16 }}>
            {loadingDocument ? (
              <div className='text-center'>
                <CircularProgress />
              </div>
            ) : (
              <>
                {selectedDocument && (
                  <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
                )}
              </>
            )}
          </div>
          <div style={{ marginTop: 16 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
              <OutlinedInput
                id="comment-textarea"
                multiline
                rows={4}
                value={commentInput}
                onChange={handleCommentChange}
              />
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleAddComment} style={{ marginTop: 16 }}>
              Add Comment
            </Button>
          </div>
          <div style={{ marginTop: 16, maxHeight: '300px', overflowY: 'auto' }}>
            <Typography variant="body1">{comments[selectedDocument.documentid]}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormList;

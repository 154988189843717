import React, { useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';

const Layout = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
    
    
  
          <Navbar f responsive={true} darkTheme={darkTheme} toggleTheme={toggleTheme} />
    <div className={`container-fluid ${darkTheme ? 'dark' : 'light'}`}>
        <div className="row">
            <div className={`col-lg-1 fixed${collapsed ? 'collapsed' : ''}`}>
              <Sidebar darkTheme={darkTheme} collapsed={collapsed} toggleCollapse={toggleCollapse} />
            </div>
            <div className={`content col-lg-${collapsed ? '10' : '11'}`} style={{ backgroundColor: '#F8F7FE' , border:''}}>
              {children}
            </div>
          </div>
        </div>
     
  
    </>
  );
};

export default Layout;

import './Home.css';
import img from './start_up.svg';

const Home = () => {

    return (
        <>
        <div className="bg-gradient-primary">
  <nav className="navbar navbar-expand-lg navbar-dark">
    <div className="container">
      {" "}
      <a className="navbar-brand" href="/">
        <img
          src={''}
          alt="Logo"
          width={220}
          height={50}
          className="img-fluid"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navBars"
        aria-controls="navBars"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navBars">
        <ul className="navbar-nav ms-auto align-items-lg-center">
          <li className="nav-item">
           
            <a className="nav-link" href="/register">
              Register
            </a>
          </li>
          <li className="nav-item">
            
            <a className="nav-link" href="/register">
            
              About
            </a>
          </li>
          <li className="nav-item">
         
            <a className="nav-link" href="/register">
            
              Pricing
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link btn btn-white px-3" href="/register" target="_blank">
              {" "}
              Live Chat
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-6 py-5">
        <div className="text-center text-lg-start">
          <div className="mb-3">
            <span className="badge bg-secondary rounded">New</span>
            <span className="text-light ms-1">New Generation </span>
          </div>
          <div className="mb-5">
            <h1 className="display-5 fw-bold text-white mb-3">
              <span className="text-warning">Online Resume</span>
              <br /> 
            </h1>
            <p className="lead text-light">
             
            </p>
          </div>
          <a className="btn btn-white hover-button-up" href="#">
            <svg
              xmlns={img}
              className="me-1"
              width="1.2rem"
              height="1.2rem"
              fill="#fff"
              viewBox="0 0 512 512"
            >
              <path
                d="M315.27,33,96,304H224L192.49,477.23a2.36,2.36,0,0,0,2.33,2.77h0a2.36,2.36,0,0,0,1.89-.95L416,208H288L319.66,34.75A2.45,2.45,0,0,0,317.22,32h0A2.42,2.42,0,0,0,315.27,33Z"
                style={{
                  fill: "none",
                  stroke: "currentColor",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 32
                }}
              />
            </svg>{" "}
            Test Drive
          </a>
        </div>
      </div>
      <div className="col-lg-6 text-center align-self-end">
        <img
          className="img-fluid animated-up-down"
          src={img}
          alt="images title"
          style={{ marginBottom: "-2%" }}
        />
      </div>
    </div>
  </div>
</div>

        </>
    )
}

export default Home